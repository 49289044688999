import { FC } from 'react';

import { withStyles } from '@material-ui/core';
import { GetApp, Print } from '@material-ui/icons';

import { isDownloadingCostReportToExcelVar } from '../../../api/apollo/reactiveVars';
import { RouteKeys } from '../../../routes/paths';
import { COST_REPORT_EXPORT } from '../../../tagConstants';
import { Export } from '../../Icons/Export';
import IconMenu from '../../Select/SelectMenu/IconMenu';

import styles from './CostReportExportPanelStyles';

type CostReportExportPanelProps = {
  classes: Classes<typeof styles>;
  printOnClick: (route: RouteKeys) => void;
  exportCostReport: () => void;
};

const CostReportExportPanel: FC<CostReportExportPanelProps> = ({
  classes,
  printOnClick,
  exportCostReport,
}) => {
  const options = [
    {
      callback: () => printOnClick(RouteKeys.PRINT_PROJECT_MSR_SUMMARY),
      icon: <Print />,
      name: 'Print Report with Cost Summary',
    },
    {
      callback: () => printOnClick(RouteKeys.PRINT_PROJECT_MSR),
      icon: <Print />,
      name: 'Print Report Only',
    },
    {
      callback: () => exportCostReport(),
      icon: <GetApp />,
      name: 'Download .xlsx',
      loadingVar: isDownloadingCostReportToExcelVar,
    },
  ];

  return (
    <div className={classes.padding}>
      <IconMenu cy={COST_REPORT_EXPORT} icon={<Export />} isBottomOriented options={options} />
    </div>
  );
};

export default withStyles(styles)(CostReportExportPanel);
