import { SET_PROJECT_LOCATION_PLACEHOLDER } from '../../../../../constants';
import {
  LocationDetailsInput,
  ProgramCreationInput,
  ProgramProject,
  ProjectType,
} from '../../../../../generated/graphql';
import ProjectTypeSelect from '../../../../dragon-scales/ProjectTypeSelect/ProjectTypeSelect';
import { Button, DialogContent, DialogFlowStep, Select, TextInput } from '../../../../scales';
import PlacesAutocompleteWrapper from '../../../../shared-widgets/PlacesAutocomplete/PlacesAutocompleteWrapper';
import ProgramThumbnail from '../../../ProgramThumbnail';

type Props = {
  canSubmit: boolean;
  input: ProgramCreationInput;
  isSubmitting: boolean;
  isCreate: boolean;
  projectTypes: ProjectType[];
  selectedProjects: ProgramProject[];
  setInput: (newInput: ProgramCreationInput) => void;
};

export default function getProgramDetailsStep(props: Props): DialogFlowStep {
  const buttonLabel = props.isCreate ? 'Create Program' : 'Save Changes';

  return {
    title: 'New Program',
    renderContent: () => (
      <ProgramDetailsContent
        input={props.input}
        isSubmitting={props.isSubmitting}
        projectTypes={props.projectTypes}
        selectedProjects={props.selectedProjects}
        setInput={props.setInput}
      />
    ),
    renderFooterRight: (args) => {
      return (
        <Button
          isDisabled={!props.canSubmit}
          label={buttonLabel}
          onClick={() => args.onNext()}
          type="primary"
        />
      );
    },
  };
}

type ProgramDetailsContentProps = {
  input: ProgramCreationInput;
  isSubmitting: boolean;
  projectTypes: ProjectType[];
  selectedProjects: ProgramProject[];
  setInput: (newInput: ProgramCreationInput) => void;
};

function ProgramDetailsContent(props: ProgramDetailsContentProps) {
  return (
    <DialogContent>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <div className="type-heading3">Program Details</div>
          <div>Add details to your Program, these can be edited at any time</div>
          <TextInput
            isDisabled={props.isSubmitting}
            label="Program Name *"
            onChange={(name: string) => props.setInput({ ...props.input, name })}
            value={props.input.name}
          />
          <div className="flex gap-2">
            <ProjectTypeSelect
              entries={props.projectTypes}
              label="Program Type *"
              onChange={(newTypeID) => props.setInput({ ...props.input, typeID: newTypeID })}
              value={props.input.typeID}
            />
            <PlacesAutocompleteWrapper
              defaultValue={props.input.locationDetails.name}
              disabled={props.isSubmitting}
              label="Program Location (City) *"
              onChange={(locationDetails?: LocationDetailsInput) => {
                if (locationDetails) props.setInput({ ...props.input, locationDetails });
              }}
              placeholder={SET_PROJECT_LOCATION_PLACEHOLDER}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="type-heading3">Program Image</div>
          <div className="flex items-center gap-12">
            <div className="flex-grow">
              <Select
                entries={props.selectedProjects.map(({ id, name }) => ({ id, label: name }))}
                isDisabled={props.isSubmitting}
                label="Project to use as Main Program Image *"
                onChange={(imageProjectID: string) =>
                  props.setInput({ ...props.input, imageProjectID })
                }
                value={props.input.imageProjectID}
              />
            </div>
            <div className="ml-6 flex h-[120px] w-[120px] flex-col items-center bg-background-1">
              <div className="m-2 text-center type-label">Preview</div>
              <ProgramThumbnail
                projectCount={props.selectedProjects.length}
                projectThumbnail={
                  props.selectedProjects.find(({ id }) => id === props.input.imageProjectID)
                    ?.thumbnail ?? ''
                }
              />
            </div>
          </div>
        </div>
      </div>
    </DialogContent>
  );
}
