import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import DateFnsUtils from '@date-io/date-fns';

import { reportItemsListBulkEditSubmit } from '../../../analytics/analyticsEventProperties';
import { ToastType } from '../../../api/gqlEnums';
import {
  ASSIGNEE,
  BULK_EDIT,
  DUE_DATE,
  ITEM_CATEGORIES,
  UNASSIGNED,
  UNCATEGORIZED_ID,
} from '../../../constants';
import { PermissionResource, Status } from '../../../generated/graphql';
import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../../hooks/useProjectCategorizationsQuery';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { setToast } from '../../../hooks/useToastParametersLocalQuery';
import { withStyles } from '../../../theme/komodo-mui-theme';
import {
  isDateValid,
  localeDateFormat,
  maskFn,
  placeholderDefault,
} from '../../../utilities/dates';
import { getItemStatusLabel } from '../../../utilities/item-status';
import {
  calculateDisableApplyChangesButton,
  calculateItemsListItemsAndOptions,
  calculateMilestoneUpdateProps,
  calculateSelectedItemsAndOptionsListsTree,
  calculateStatusUpdateProps,
  getDisabledBulkEditPanelLabel,
} from '../../../utilities/items';
import { PartialCategory } from '../../../utilities/permissions/utils';
import { statuses } from '../../../utilities/sorting';
import { removeYear } from '../../../utilities/string';
import BulkEditPanel from '../../BulkEditPanel/BulkEditPanel';
import InputsSelectAssigneeData from '../../Inputs/InputsSelectAssignee/InputsSelectAssigneeData';
import { Tooltip } from '../../scales';
import JoinSelect, { getEntry } from '../../Select/JoinSelect/JoinSelect';
import PanelSelectHeader from '../../Select/PanelSelectHeader/PanelSelectHeader';
import {
  SelectCategoryValue,
  isSelectCategoryInteraction,
  uncategorizedOption,
} from '../../Select/SelectCategory/SelectCategoryUtils';
import SelectCategoryChipInputSingle from '../../Select/SelectCategoryChipInput/SelectCategoryChipInputSingle';
import useMemoWrapper from '../../useMemoWrapper';

import useUpdateBulkItems from './ItemsListBulkEditingPanelHook';
import styles from './ItemsListBulkEditingPanelStyles';
import {
  calculateBulkItemUpdateAnalytics,
  calculateBulkUpdateToastMessage,
  formatBulkItemsUpdateInput,
} from './ItemsListBulkEditingPanelUtils';

const SELECTION_DISABLED = 'Selection is disabled';

const getDefaultSelection = (updateProps: { isEditable: boolean; defaultLabel: string }) =>
  updateProps.isEditable ? updateProps.defaultLabel : SELECTION_DISABLED;

type ItemsListBulkEditingPanelProps = {
  canEdit: (resource: PermissionResource, args?: { trades: PartialCategory[] }) => boolean;
  classes: Classes<typeof styles>;
  hasDraftItems: boolean;
  itemsLikeIDsSelected: UUID[];
  itemsList?: ItemsListItem[];
  milestones: Milestone[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  setMilestoneIDsSelected: any;
};

const ItemsListBulkEditingPanel: FC<ItemsListBulkEditingPanelProps> = ({
  canEdit,
  classes,
  hasDraftItems,
  itemsLikeIDsSelected,
  itemsList,
  milestones,
  setMilestoneIDsSelected,
}) => {
  // CONSTANTS
  const cleanInput: ItemsListBulkEditingInput = {
    milestoneID: '',
    status: undefined,
    meetingID: '',
    clearMeetingID: false,
    assigneeEmail: '',
    clearAssigneeEmail: false,
    dueDate: undefined,
    clearDueDate: false,
    addCategories: [],
    clearCategories: [],
  };

  const sendAnalytics = useSendAnalytics();

  // STATE VARIABLE
  const [input, setItemsListBulkEditingInput] = useState(cleanInput as ItemsListBulkEditingInput);
  const [date, setDate] = useState<string | null>(null);

  // CATEGORIZATIONS
  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID not found');

  const { data } = useProjectCategorizationsQuery(projectId);
  const categorizations = getCategorizationsForProjectFromQueryData(data);

  const selectedItems = useMemoWrapper(
    calculateSelectedItemsAndOptionsListsTree,
    itemsList ?? [],
    itemsLikeIDsSelected
  );
  const itemsAndOptions = useMemoWrapper(calculateItemsListItemsAndOptions, selectedItems);

  // PERMISSIONS
  const canEditItemsAssignee = selectedItems.every((item) =>
    canEdit(PermissionResource.ITEM_ASSIGNEES, { trades: item.categories })
  );
  const canEditItemsCategories = selectedItems.every((item) =>
    canEdit(PermissionResource.ITEM_CATEGORIES, { trades: item.categories })
  );
  const canEditItemsMilestone = selectedItems.every((item) =>
    canEdit(PermissionResource.ITEM_MILESTONE_AND_MEETINGS, { trades: item.categories })
  );
  const canEditItemsStatus = selectedItems.every((item) =>
    canEdit(PermissionResource.ITEM_STATUS, { trades: item.categories })
  );

  const statusUpdateProps: StatusUpdateProps = useMemoWrapper(
    calculateStatusUpdateProps,
    canEditItemsStatus,
    selectedItems.map((itemLike) => ({ ...itemLike, parent: itemLike.parentID })),
    itemsAndOptions
  );

  const milestoneUpdateProps: MilestoneUpdateProps = useMemoWrapper(
    calculateMilestoneUpdateProps,
    canEditItemsMilestone,
    itemsLikeIDsSelected,
    itemsList ?? []
  );

  const disableApplyChangesButton = useMemoWrapper(calculateDisableApplyChangesButton, input);

  const displayCategory = useCallback(
    (categorizationID: string) => {
      if (input.clearCategories && input.clearCategories.some((c) => c === categorizationID)) {
        return uncategorizedOption;
      }
      return (
        input.addCategories?.find(
          (c: Category) => c.categorization && c.categorization.id === categorizationID
        ) ?? undefined
      );
    },
    [input.clearCategories, input.addCategories]
  );

  // BULK UPDATE
  const clearAllSelectedFields = () => {
    setItemsListBulkEditingInput(cleanInput);
    setDate(null);
  };

  const onSuccess = (itemCount: number, optionCount: number) => {
    setToast(
      { message: calculateBulkUpdateToastMessage(itemCount, optionCount, input) },
      ToastType.BULK_ITEM_UPDATE,
      null
    );
    clearAllSelectedFields();
  };
  const showToast = (message: string) => {
    setToast({ message });
  };
  const updateBulkItems = useUpdateBulkItems(showToast, onSuccess);

  const onSubmit = () => {
    const itemsInput = formatBulkItemsUpdateInput(itemsLikeIDsSelected, input);
    if (updateBulkItems) {
      updateBulkItems(projectId, itemsInput);
      setMilestoneIDsSelected([itemsInput.milestoneID]);
    }
    sendAnalytics(
      reportItemsListBulkEditSubmit(
        itemsLikeIDsSelected.length,
        calculateBulkItemUpdateAnalytics(input)
      )
    );
  };

  // STATE MANAGEMENT
  const cleanAssignee = { ...input, clearAssigneeEmail: false, assigneeEmail: '' };
  const [assigneeIsReset, setAssigneeReset] = useState<boolean>(false);
  const setAddAssignee = (email: string) => {
    if (!email) {
      setItemsListBulkEditingInput(cleanAssignee);
    } else if (email === UNASSIGNED) {
      setItemsListBulkEditingInput({
        ...input,
        clearAssigneeEmail: true,
        assigneeEmail: '',
      });
    } else {
      setItemsListBulkEditingInput({
        ...input,
        assigneeEmail: email,
        clearAssigneeEmail: false,
      });
    }
    setAssigneeReset(false);
  };
  const setClearAssignee = () => {
    setItemsListBulkEditingInput(cleanAssignee);
    setAssigneeReset(true);
  };
  const showAssigneeReset = !!input.clearAssigneeEmail || input.assigneeEmail !== '';

  const setAddCategories = (cat: SelectCategoryValue[], categorizationID: string) => {
    if (cat[0].id === UNCATEGORIZED_ID) {
      setItemsListBulkEditingInput({
        ...input,
        addCategories:
          input.addCategories &&
          input.addCategories.filter(
            (c: Category) =>
              c.categorization && c.categorization.id && c.categorization.id !== categorizationID
          ),
        clearCategories: input.clearCategories && [...input.clearCategories, categorizationID],
      });
    }
    if (!isSelectCategoryInteraction(cat[0]) && (cat[0] as Category).id !== UNCATEGORIZED_ID) {
      const addCategories = input.addCategories.filter(
        (c: Category) =>
          c.categorization && c.categorization.id && c.categorization.id !== categorizationID
      );
      setItemsListBulkEditingInput({
        ...input,
        addCategories: [...addCategories, cat[0]],
        clearCategories:
          input.clearCategories && input.clearCategories.filter((c) => categorizationID !== c),
      });
    }
  };
  const setClearCategory = (categorizationID: UUID) => {
    const addCategories = input.addCategories.filter(
      (c: Category) =>
        c.categorization && c.categorization.id && c.categorization.id !== categorizationID
    );
    const clearCategorization = () => {
      setItemsListBulkEditingInput({
        ...input,
        addCategories,
        clearCategories:
          input.clearCategories && input.clearCategories.filter((c) => categorizationID !== c),
      });
    };
    return clearCategorization;
  };
  const showCategoryReset = (categorizationID: UUID) => {
    const { addCategories, clearCategories } = input;
    const hasCategory = !!addCategories.find(
      (c: Category) =>
        c.categorization && c.categorization.id && c.categorization.id === categorizationID
    );
    const hasClearCategory = !!(
      clearCategories && clearCategories.find((cleared: string) => cleared === categorizationID)
    );
    return hasCategory || hasClearCategory;
  };

  const setAddNewMilestone = (milestoneID: string) => {
    setItemsListBulkEditingInput({
      ...input,
      milestoneID,
      meetingID: '',
    });
  };
  const setClearMilestone = () => {
    setItemsListBulkEditingInput({ ...input, milestoneID: '' });
  };
  const showMilestoneReset = input.milestoneID !== '';

  const setAddNewStatus = (status: Status | undefined) => {
    setItemsListBulkEditingInput({
      ...input,
      status,
    });
  };
  const setClearStatus = () => {
    setItemsListBulkEditingInput({ ...input, status: undefined });
  };
  const showStatusReset = !!input.status;

  // DUE DATE
  const checkValidDateAndSave = (newDate: string | null) => {
    if (!newDate || isDateValid(newDate)) {
      setDate(newDate);
      setItemsListBulkEditingInput({
        ...input,
        dueDate: newDate,
      });
    }
  };
  const setClearDueDate = () => {
    setItemsListBulkEditingInput({ ...input, dueDate: undefined, clearDueDate: false });
    setDate(null);
  };
  const showDueDateReset = !!input.dueDate || !!input.clearDueDate;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const handleKeydown = (event: any) => {
    if (event.key === 'Enter') {
      // call blur to first update the name / date, then send the changes
      if (event.target.className.includes('picker')) {
        event.target.blur();
      }
      setDate(date);
    }
  };

  // Hook to catch the change of itemsLikeSelected and reset input
  // if status and milestones fields are no longer editable or
  // have selected status which is not any longer valid.
  useEffect(() => {
    if (!milestoneUpdateProps.isEditable) {
      setAddNewMilestone('');
    }
    if (
      !statusUpdateProps.isEditable ||
      !statusUpdateProps.availableStates.some((status) => input.status === status)
    ) {
      setItemsListBulkEditingInput({
        ...input,
        milestoneID: '',
        meetingID: '',
        status: undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsLikeIDsSelected]);

  const assigneeSelector = (
    <Tooltip content="Draft items selected" isDisabled={!hasDraftItems}>
      <div
        className={`${showAssigneeReset && classes.activeContainer} ${classes.controlContainer}`}
        title={
          canEditItemsAssignee
            ? 'Choose Assignee'
            : getDisabledBulkEditPanelLabel(ASSIGNEE, selectedItems.length)
        }
      >
        <PanelSelectHeader
          clearFilter={setClearAssignee}
          showRemove={showAssigneeReset}
          title="Assignee"
          variant={BULK_EDIT}
        />
        <InputsSelectAssigneeData
          defaultLabel={hasDraftItems ? 'Selection is disabled' : 'Choose Assignee'}
          disabled={!canEditItemsAssignee || hasDraftItems}
          isReset={assigneeIsReset}
          onChange={(email: string | undefined) => setAddAssignee(email ?? UNASSIGNED)}
          projectId={projectId}
          selected={input.assigneeEmail}
          variant={BULK_EDIT}
        />
      </div>
    </Tooltip>
  );

  const nonCategorySelectors = (
    <div>
      <div
        className={`${showStatusReset && classes.activeContainer} ${classes.controlContainer}`}
        title={statusUpdateProps.tooltipText}
      >
        <PanelSelectHeader
          clearFilter={setClearStatus}
          showRemove={showStatusReset}
          title="Status"
          variant={BULK_EDIT}
        />
        <JoinSelect
          cySelect="bulkEditingPanel-Status"
          disabled={!statusUpdateProps.isEditable}
          entries={statuses.map((status: string) =>
            getEntry(
              status,
              getItemStatusLabel(status),
              !statusUpdateProps.availableStates.some((available: string) => status === available)
            )
          )}
          hidePrint={false}
          onChange={(value) => setAddNewStatus(value as Status)}
          value={input.status || ''}
          valuePlaceholder={getDefaultSelection(statusUpdateProps)}
        />
      </div>
      {assigneeSelector}
      <div
        className={`${showDueDateReset && classes.activeContainer} ${classes.controlContainer}`}
        title={
          canEditItemsAssignee
            ? 'Choose Due Date'
            : getDisabledBulkEditPanelLabel(DUE_DATE, selectedItems.length)
        }
      >
        <PanelSelectHeader
          clearFilter={setClearDueDate}
          showRemove={showDueDateReset}
          title="Due Date"
          variant={BULK_EDIT}
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            animateYearScrolling={false}
            className={classes.date}
            clearable
            data-cy="input-date-picker-bulkPanel"
            disabled={!canEditItemsAssignee}
            format={localeDateFormat}
            FormHelperTextProps={{ classes: { error: classes.error } }}
            fullWidth
            InputProps={{
              classes: { input: `picker ${classes.picker}` },
              disableUnderline: true,
            }}
            invalidDateMessage=" "
            keepCharPositions
            keyboard
            KeyboardButtonProps={{
              classes: { root: classes.datePickerIcon },
            }}
            mask={maskFn}
            minDateMessage=""
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
            onBlur={(event: any) => {
              event.stopPropagation();
              checkValidDateAndSave(event.target.value);
            }}
            onChange={checkValidDateAndSave}
            onKeyDown={handleKeydown}
            placeholder={placeholderDefault}
            value={date}
          />
        </MuiPickersUtilsProvider>
      </div>
      <div
        className={`${showMilestoneReset && classes.activeContainer} ${classes.controlContainer}`}
        title={milestoneUpdateProps.defaultLabel}
      >
        <PanelSelectHeader
          clearFilter={setClearMilestone}
          showRemove={showMilestoneReset}
          title="Milestone"
          variant={BULK_EDIT}
        />
        <JoinSelect
          cySelect="bulkEditingPanel-Milestone"
          disabled={!milestoneUpdateProps.isEditable}
          entries={milestones.filter((m: Milestone) => !m.isDraft)}
          hidePrint={false}
          onChange={(mID) => setAddNewMilestone(mID || '')}
          value={input.milestoneID}
          valuePlaceholder={getDefaultSelection(milestoneUpdateProps)}
        />
      </div>
      <div />
    </div>
  );

  const categorySelectors = (
    <div>
      {categorizations &&
        categorizations.map((categorization) => (
          <div
            key={categorization.id}
            className={`${showCategoryReset(categorization.id) && classes.activeContainer} ${
              classes.controlContainer
            }`}
            title={
              canEditItemsCategories
                ? 'Choose Category'
                : getDisabledBulkEditPanelLabel(ITEM_CATEGORIES, selectedItems.length)
            }
          >
            <PanelSelectHeader
              clearFilter={setClearCategory(categorization.id)}
              showRemove={showCategoryReset(categorization.id)}
              title={removeYear(
                categorization.name,
                (categorization as CategorizationFeDefined).builtin
              )}
              variant={BULK_EDIT}
            />
            <SelectCategoryChipInputSingle
              categorizations={[categorization]}
              disabled={!canEditItemsCategories}
              id="editPanel"
              includeUncategorizedCategory
              placeholder={canEditItemsCategories ? 'Choose Category' : 'Selection is disabled'}
              selectedCategory={displayCategory(categorization.id)}
              setCategory={(selected: SelectCategoryValue) =>
                setAddCategories([selected], categorization.id)
              }
            />
          </div>
        ))}
    </div>
  );

  return (
    <BulkEditPanel
      categorySelectors={categorySelectors}
      clearAllSelectedFields={clearAllSelectedFields}
      disabled={disableApplyChangesButton}
      itemsLikeIDsSelected={itemsLikeIDsSelected}
      nonCategorySelectors={nonCategorySelectors}
      onSubmit={onSubmit}
    />
  );
};

export default withStyles(styles)(ItemsListBulkEditingPanel);
