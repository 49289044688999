import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { ItemDataQuery, PermissionResource } from '../../../../generated/graphql';
import { MountPolicy } from '../../../../hooks/usePolicyOnFirstMount';
import usePermissions from '../../../../utilities/permissions/usePermissions';
import { useUpdateScheduleImpact } from '../../../ve-item-details/ItemDetailsHooks';

import ScheduleImpactWrapper from './ScheduleImpactWrapper';

type ScheduleImpactWrapperItemDataProps = {
  item: ItemDataQuery['item'];
  isSidebar?: boolean;
  localImpact: ScheduleImpact;
  scheduleImpact: ScheduleImpact;
  setLocalImpact: React.Dispatch<React.SetStateAction<ScheduleImpact>>;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  readOnly?: boolean;
  onItemMutated?: () => void;
};

const ScheduleImpactWrapperItemData: FC<ScheduleImpactWrapperItemDataProps> = ({
  item,
  isSidebar = false,
  localImpact,
  scheduleImpact,
  setLocalImpact,
  readOnly,
  onItemMutated,
}) => {
  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID not found');

  // Permissions
  const { canView, canEdit } = usePermissions(
    { trades: item?.categories },
    MountPolicy.SKIP_ON_MOUNT
  );
  const canEditItemScheduleImpact = canEdit(PermissionResource.SCHEDULE_IMPACT);
  const canViewItemScheduleImpact = canView(PermissionResource.SCHEDULE_IMPACT);

  const hasScheduleImpactEditPermission = Boolean(
    !readOnly && item && item.id && canEditItemScheduleImpact
  );
  const hasScheduleImpactViewPermission = Boolean(item && item.id && canViewItemScheduleImpact);

  // Analytics
  // Mutations
  const updateScheduleImpact = useUpdateScheduleImpact(projectId, item?.id ?? '', {
    onCompleted: onItemMutated,
  });

  if (!item) return null;

  return (
    <ScheduleImpactWrapper
      key={item.id}
      hasScheduleImpactEditPermission={hasScheduleImpactEditPermission}
      hasScheduleImpactViewPermission={hasScheduleImpactViewPermission}
      isSidebar={isSidebar}
      localImpact={localImpact}
      onItemMutated={onItemMutated}
      scheduleImpact={scheduleImpact}
      setLocalImpact={setLocalImpact}
      setScheduleImpact={updateScheduleImpact}
    />
  );
};

export default ScheduleImpactWrapperItemData;
