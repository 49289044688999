/* eslint-disable func-names */

import type { ScaleTime } from 'd3-scale';
import { select } from 'd3-selection';

import './timeline.scss';

import {
  BaseItemBaseSelection,
  DivSelection,
  EnterItemSelection,
  ItemDueState,
  SVGGItemBaseSelection,
  SVGGItemSelection,
  SVGSVGSelection,
  TimelineItemGroup,
  type TimelineOptions,
} from './timeline.types';
import { updateGridlines, updateTodayLine } from './timelineDecorations';
import { updateTooltips } from './timelineTooltips';
import { FONT_STYLE, FONT_STYLE_DETAILS, ITEMS_HEIGHT, ITEMS_HEIGHT_DETAILS } from './utils';

const ITEMS_COLLAPSED_HEIGHT = 30;
const ITEMS_TOP_PADDING = 36;
const BAR_GAP = 4;
const BAR_MIN_HEIGHT = 5;
const LABEL_HEIGHT = 38;
const LABEL_TO_BAR_GAP = 8;

export const getUpcomingHeight = (d: TimelineItemGroup, maxLength: number, maxHeight: number) =>
  ((d.data.length - d.counter[ItemDueState.Decided] - d.counter[ItemDueState.PastDue]) /
    maxLength) *
  maxHeight;

export const getDecidedHeight = (d: TimelineItemGroup, maxLength: number, maxHeight: number) =>
  ((d.data.length - d.counter[ItemDueState.Upcoming] - d.counter[ItemDueState.PastDue]) /
    maxLength) *
  maxHeight;

export const getPastDueHeight = (d: TimelineItemGroup, maxLength: number, maxHeight: number) =>
  ((d.data.length - d.counter[ItemDueState.Decided] - d.counter[ItemDueState.Upcoming]) /
    maxLength) *
  maxHeight;

export const getDueStateHeights = (d: TimelineItemGroup, maxLength: number, maxHeight: number) => {
  let pastDueHeight = getPastDueHeight(d, maxLength, maxHeight);
  let decidedHeight = getDecidedHeight(d, maxLength, maxHeight);
  let upcomingHeight = getUpcomingHeight(d, maxLength, maxHeight);
  const totalHeight = pastDueHeight + decidedHeight + upcomingHeight;
  if (totalHeight < BAR_MIN_HEIGHT) {
    const fraction = BAR_MIN_HEIGHT / totalHeight;
    pastDueHeight *= fraction;
    decidedHeight *= fraction;
    upcomingHeight *= fraction;
  }
  return { decidedHeight, pastDueHeight, upcomingHeight };
};

function renderItems(
  enter: EnterItemSelection,
  _chart: DivSelection,
  timelineXScale: ScaleTime<number, number>,
  _dragAndZoomXScale: ScaleTime<number, number>,
  _brushXScale: ScaleTime<number, number>,
  options: TimelineOptions
) {
  const { itemGroups } = options;
  const BARS_MAX_HEIGHT =
    !options.settings.isMiniChart && options.settings.isInsights
      ? ITEMS_HEIGHT_DETAILS
      : ITEMS_HEIGHT - ITEMS_TOP_PADDING;
  const labelHeight = options.settings.isInsights ? LABEL_HEIGHT : 0;
  const itemsHeight = options.items.length ? ITEMS_HEIGHT : 0;
  const relative = labelHeight / itemsHeight;

  let maxLength = itemGroups.reduce((a, { data }) => (data.length > a ? data.length : a), 0);
  const maxItemsCount = maxLength;

  // Add gap to max value
  maxLength += maxLength * 0.2;
  // Display label only once
  let hasLabel = false;
  const bar = enter
    .append('g')
    .attr('class', () => 'itempoint')
    .each(function (d) {
      const startX = timelineXScale(new Date(d.start).getTime());
      const { decidedHeight, pastDueHeight, upcomingHeight } = getDueStateHeights(
        d,
        maxLength + maxLength * relative,
        BARS_MAX_HEIGHT
      );
      const node: SVGGItemSelection = select(this);
      node.attr('y', itemsHeight).attr('transform', `translate(${startX}, ${0})`);
      node
        .append('rect')
        .attr('class', 'rect-svg fill-entities-item-pastdue stroke-entities-item-pastdue')
        .attr('y', 0)
        .attr('height', pastDueHeight);
      node
        .append('rect')
        .attr('class', 'rect-svg fill-entities-item-upcoming stroke-entities-item-upcoming')
        .attr('y', pastDueHeight)
        .attr('height', upcomingHeight);
      node
        .append('rect')
        .attr('class', 'rect-svg fill-entities-item-decided stroke-entities-item-decided')
        .attr('y', pastDueHeight + upcomingHeight)
        .attr('height', decidedHeight);

      if (options.settings.isInsights) {
        const itemsCount =
          d.counter[ItemDueState.Decided] +
          d.counter[ItemDueState.PastDue] +
          d.counter[ItemDueState.Upcoming];
        const itemsTotalHeight = decidedHeight + pastDueHeight + upcomingHeight;
        if (maxItemsCount === itemsCount && !hasLabel) {
          hasLabel = true;
          node
            .append('text')
            .attr(
              'class',
              `fill-type-muted ${options.settings.isMiniChart ? 'type-body3' : 'type-body2'}`
            )
            .style('font', options.settings.isMiniChart ? FONT_STYLE : FONT_STYLE_DETAILS)
            .attr('transform', 'scale(1, -1)')
            .attr('text-anchor', 'middle')
            .attr('dominant-baseline', 'middle')
            .attr('y', -itemsTotalHeight - LABEL_TO_BAR_GAP)
            .text(() => itemsCount);

          const maxLineY = decidedHeight + pastDueHeight + upcomingHeight;
          // eslint-disable-next-line no-param-reassign
          options.settings.maxLineY = maxLineY;
        }
      }
    });
  return bar;
}

export function addItemsContainer(chart: DivSelection, options: TimelineOptions) {
  if (!options.isItems) {
    addItemsCollapsedContainer(chart);
    return;
  }

  const itemsContainerCollapsed: DivSelection = chart.select('#item-container-collapsed');
  if (!itemsContainerCollapsed.empty()) {
    itemsContainerCollapsed.remove();
  }

  let itemsContainer: DivSelection = chart.select('#item-container');
  const itemsHeight = options.height.itemsHeight;

  // Create items container
  if (itemsContainer.empty()) {
    itemsContainer = chart
      .insert('div', '#timeline-x-axis-bottom')
      .attr('id', 'item-container')
      .style('width', '100%')
      .style('height', `${itemsHeight}px`);
  }

  let itemsSvg: SVGSVGSelection = itemsContainer.select('#items-svg');

  if (itemsSvg.empty()) {
    // Create items svg
    itemsSvg = itemsContainer
      .append('svg')
      .attr('id', 'items-svg')
      .attr('width', '100%')
      .attr('height', `${itemsHeight}px`)
      .attr('transform', 'scale(1,-1)');
  }
}

export function updateItems(
  chart: DivSelection,
  timelineXScale: ScaleTime<number, number>,
  dragAndZoomXScale: ScaleTime<number, number>,
  brushXScale: ScaleTime<number, number>,
  options: TimelineOptions
): void {
  const itemsContainer: DivSelection = chart.select('#item-container');
  const itemsSvg: SVGSVGSelection = itemsContainer.select('#items-svg');

  const gridlines = itemsSvg.select('.gridlines');
  if (gridlines.empty()) {
    // Add gridlines group
    itemsSvg.append('g').attr('class', 'gridlines');
  }

  const { itemGroups, settings } = options;
  const hasWithoutDue = settings.hasWithoutDue;

  if (!hasWithoutDue) {
    // Add svg group for each data point in dataset group to svg
    const datapoints: BaseItemBaseSelection = itemsSvg.selectAll('g.itempoint');

    datapoints
      .data(itemGroups, (d) => d.start.toString())
      .join(
        function enter(enter) {
          return renderItems(enter, chart, timelineXScale, dragAndZoomXScale, brushXScale, options);
        },
        function update(update) {
          updateItemsInternal(chart, timelineXScale, options);
          return update;
        },
        function exit(exit) {
          return exit.remove();
        }
      );
    updateMaxLine(chart, options, '#items-svg');
    updateTodayLine(chart, timelineXScale, options, '#items-svg', options.height.itemsHeight);
  }

  updateGridlines(chart, timelineXScale, options, '#items-svg', options.height.itemsHeight);
}

export function updateItemsInternal(
  chart: DivSelection,
  timelineXScale: ScaleTime<number, number>,
  options: TimelineOptions
): void {
  const hasWithoutDue = options.settings.hasWithoutDue;
  if (hasWithoutDue) return;

  const groupId = '#item-container';

  const svgGroups: SVGGItemBaseSelection = chart.select(groupId).selectAll('g.itempoint');

  // Set x,y for each bar
  svgGroups.attr(
    'transform',
    (d) => `translate(${timelineXScale(new Date(d.start).getTime())}, ${0})`
  );

  // Set width for each interval data point
  const intervalNodes: BaseItemBaseSelection = svgGroups.selectAll('rect');
  intervalNodes.attr(
    'width',
    (d: TimelineItemGroup) =>
      timelineXScale(new Date(d.end)) - timelineXScale(new Date(d.start)) - BAR_GAP
  );

  // Set x for text label
  const textNodes: BaseItemBaseSelection = svgGroups.selectAll('text');
  textNodes.attr(
    'x',
    (d: TimelineItemGroup) =>
      (timelineXScale(new Date(d.end)) - timelineXScale(new Date(d.start)) - BAR_GAP) / 2
  );

  updateGridlines(chart, timelineXScale, options, '#items-svg', options.height.itemsHeight);
  updateTodayLine(chart, timelineXScale, options, '#items-svg', options.height.itemsHeight);
  updateTooltips(svgGroups, options);
}

export function removeItems(chart: DivSelection): void {
  const groupId = '#item-container';
  // Remove svg group for each data point in dataset group to svg
  chart.select(groupId).selectAll('g.itempoint').remove();
}

export function addItemsCollapsedContainer(chart: DivSelection) {
  const itemsContainer: DivSelection = chart.select('#item-container');
  if (!itemsContainer.empty()) {
    itemsContainer.remove();
  }
  const itemsContainerCollapsed: DivSelection = chart.select('#item-container-collapsed');
  if (itemsContainerCollapsed.empty()) {
    chart
      .insert('div', '#timeline-x-axis-bottom')
      .attr('id', 'item-container-collapsed')
      .style('width', '100%')
      .style('height', `${ITEMS_COLLAPSED_HEIGHT}px`);
  }
}

export const updateMaxLine = (chart: DivSelection, options: TimelineOptions, groupId: string) => {
  const { settings } = options;
  if (!settings.isInsights || !settings.maxLineY) return;

  const group = chart.select(groupId);
  if (group.empty()) return;

  const maxLine = group.select('#max-line-id');
  if (!maxLine.empty()) {
    maxLine.remove();
  }

  // Add max line
  group
    .append('line')
    .attr('class', `max-line stroke-1 stroke-chart-axis`)
    .attr('id', 'max-line-id')
    .attr('shape-rendering', 'geometricPrecision')
    .attr('stroke-dasharray', '4, 4')
    .attr('y1', settings.maxLineY)
    .attr('y2', settings.maxLineY)
    .attr('x1', 0 + 16)
    .attr('x2', options.width - 16);
};
