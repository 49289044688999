import { useState } from 'react';

import { Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons/';

import { usePreviewSettingsVar } from '../../../../api/apollo/reactiveVars';
import { PermissionResource } from '../../../../generated/graphql';
import { MountPolicy } from '../../../../hooks/usePolicyOnFirstMount';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import usePermissions from '../../../../utilities/permissions/usePermissions';
import { useCurrentUser } from '../../../contexts/current-user';
import { Button } from '../../../scales';
import { generateTitle, getItemDescriptor } from '../ItemsListItemUtils';

import ItemsListItemActionsStyles from './ItemsListItemActionsStyles';

type Props = {
  classes: Classes<typeof ItemsListItemActionsStyles>;
  item: ItemsListItem;
  onCtaClick?: () => void; // will hide button if not provided
  parentItem?: ItemsListItem;
};

const ItemsListItemActions = ({ classes, item, onCtaClick, parentItem }: Props) => {
  const [pending, setPending] = useState(false);
  // PERMISSIONS
  const userID = useCurrentUser().id;
  const { canEdit, canAdd } = usePermissions(
    {
      trades: item.categories,
    },
    MountPolicy.SKIP_ON_MOUNT
  );
  const displayUserID = usePreviewSettingsVar().userID ?? userID;
  const didCreateItem = item?.creator?.id === displayUserID;
  const isAssignedItem = item?.assigneeID === displayUserID;
  const canEditItemDetails =
    canEdit(PermissionResource.ITEM_DETAILS) ||
    (canAdd(PermissionResource.ITEM_DETAILS) && (didCreateItem || isAssignedItem));

  if (parentItem) {
    return (
      <Typography className={classes.column3Text} title={generateTitle(parentItem)}>
        {`Option in ${getItemDescriptor(parentItem)}`}
      </Typography>
    );
  }
  const numberOptions = item?.options?.length;
  if (numberOptions) {
    const message = `${numberOptions} Option${numberOptions === 1 ? '' : 's'}`;
    return <Typography className={classes.column3Text}>{message}</Typography>;
  }
  if (onCtaClick && canEditItemDetails) {
    return (
      <Button
        data-cy="add-as-option-button"
        isDisabled={pending}
        label="Add as option"
        onClick={() => {
          if (onCtaClick) {
            onCtaClick();
            setPending(true);
          }
        }}
        startIcon={<Add />}
        type="primary"
      />
    );
  }
  return null;
};

export default withStyles(ItemsListItemActionsStyles)(ItemsListItemActions);
