import { useState } from 'react';
import * as React from 'react';

import {
  CircularProgress,
  Dialog,
  DialogActions,
  Divider,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { useCreateProjectTemplateMutation } from '../../CompanyTab/Standards/ProjectTemplates/hooks';
import { Button } from '../../scales';
import DialogsStyles from '../DialogsStyles';

type DialogsDuplicateTemplateProps = {
  classes: Classes<typeof DialogsStyles>;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  open: boolean;
  onClose: () => void;
  templateID: UUID;
};

const DialogsDuplicateTemplate: React.FC<DialogsDuplicateTemplateProps> = ({
  classes,
  open,
  onClose: onDialogClose,
  templateID,
}) => {
  const sendAnalytics = useSendAnalytics();
  const createProjectTemplate = useCreateProjectTemplateMutation();
  const [loading, setLoading] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState('');

  const onClose = () => {
    onDialogClose();
    setNewTemplateName('');
  };

  const onSubmit = () => {
    setLoading(true);
    sendAnalytics(
      companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_TEMPLATES_DUPLICATE_DRAFT)
    );
    createProjectTemplate(
      newTemplateName,
      templateID,
      () => {
        setLoading(false);
        onClose();
      },
      () => {
        setLoading(false);
        onClose();
      }
    );
  };

  const header = (
    <div className={`${classes.titleContainer}`}>
      <Typography variant="title">Duplicate Template</Typography>
      <IconButton
        onClick={() => {
          sendAnalytics(
            companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_TEMPLATES_DUPLICATE_CLOSE)
          );
          onClose();
        }}
        title="Close dialog"
      >
        <Close />
      </IconButton>
    </div>
  );

  const content = (
    <div className={`${classes.horizontalContainer} ${classes.content} ${classes.paddingBottom}`}>
      <TextField
        autoFocus
        data-cy="DialogsDuplicateTemplate-name"
        disabled={loading}
        fullWidth
        InputLabelProps={{ shrink: true }}
        InputProps={{ disableUnderline: true }}
        label="Template Name"
        onChange={(evt) => setNewTemplateName(evt.target.value)}
        placeholder="Template Name"
        required
        value={newTemplateName}
      />
    </div>
  );

  const footer = (
    <DialogActions className={classes.action}>
      {loading ? (
        <div className={classes.footerCentered}>
          <CircularProgress size={18} />
          <Typography className={classes.boldBlue}>
            Creating: {newTemplateName}. This might take a few minutes.
          </Typography>
        </div>
      ) : (
        <>
          <Button
            label="Cancel"
            onClick={() => {
              onClose();
              sendAnalytics(
                companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_TEMPLATES_DUPLICATE_CANCEL)
              );
            }}
            type="secondary"
          />
          <Button
            data-cy="duplicate-template-button"
            isDisabled={!newTemplateName.trim()}
            label="Create as Draft"
            onClick={onSubmit}
            type="primary"
          />
        </>
      )}
    </DialogActions>
  );

  return (
    <Dialog
      classes={{
        paper: `${classes.dialogPaper} ${classes.dialogPaperSmall}`,
      }}
      maxWidth={false}
      onClose={onClose}
      open={open}
    >
      {header}
      <Divider />
      {content}
      {footer}
    </Dialog>
  );
};

export default withStyles(DialogsStyles)(DialogsDuplicateTemplate);
