import { FC, useState } from 'react';

import {
  DialogContent,
  IconButton,
  LinearProgress,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';

import {
  NewItemEvent,
  newItemEvent,
  optionsDialogTabAnalytics,
} from '../../../analytics/analyticsEventProperties';
import { VISIBILITY_DRAFT_ITEM } from '../../../constants';
import { CT_BRAND_ASSIST_EXPERIMENT } from '../../../features';
import { Visibility } from '../../../generated/graphql';
import { useHasFeature } from '../../../hooks/useHasFeature';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { Lightbulb } from '../../Icons/Lightbulb';
import { styleItemNumber } from '../../Items/ItemsListItem/ItemsListItemUtils';
import {
  generateCostImpactInfo,
  isOptionOfPrivateItem,
  isPrivateVisibility,
} from '../../Items/ItemsUtils';
import { Button, Button as ButtonScales, Callout, Checkbox } from '../../scales';
import DialogsStyles from '../DialogsStyles';

import { CheckBrandTriggers } from './BrandAssistUtils';
import DialogsNewItemCategories from './DialogsNewItemCategories';
import DialogsNewItemDetails from './DialogsNewItemDetails';
import DialogsNewItemSearch from './DialogsNewItemSearch';

type ScenarioProps = {
  color: string;
  name: string;
};

type DialogsNewItemContentProps = {
  classes: Classes<typeof DialogsStyles>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  convertItem?: () => Promise<any>;
  count?: number;
  isValidName: boolean;
  item: DraftItem;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  loading?: boolean;
  onClose: () => void;
  onCloseNoChange?: () => void;
  onSubmit: (event?: () => void, createAsDraft?: boolean, triggeredBrand?: string) => void;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  open: boolean;
  parentItem?: Item;
  scenarioProps?: ScenarioProps;
  setItem: (item: DraftItem) => void;
  visibilitySetting?: Visibility;
  setVisibilitySetting?: (visibility: Visibility) => void;
};

const DialogsNewItemContent: FC<DialogsNewItemContentProps> = ({
  classes,
  convertItem,
  isValidName,
  item,
  count = 1,
  loading,
  onClose,
  onCloseNoChange,
  onSubmit,
  open,
  parentItem,
  scenarioProps,
  setItem,
  visibilitySetting,
  setVisibilitySetting,
}) => {
  // CONSTANTS
  const NEW = 'new';
  const EXISTING = 'existing';

  // LOGIC
  const { activeEstimate, cost, id, name, number, visibility: parentVisibility } = parentItem || {};

  const isOption = !!parentItem;
  const isScenario = !!scenarioProps;
  const hasPrivateParentItem = isOptionOfPrivateItem(isOption, parentVisibility);
  const isConvertingToIWO = parentItem && !parentItem.options?.length;
  const brandAssistExperiment = useHasFeature(CT_BRAND_ASSIST_EXPERIMENT);
  const draftNameTag = VISIBILITY_DRAFT_ITEM;
  const onChange = (patch: DraftItem) => {
    setItem({ ...item, ...patch, dueDate: patch.dueDate ?? item.dueDate }); // dueDate can't be null
  };

  // VARIABLES
  const { categories } = item;
  const [openTab, setOpenTab] = useState(NEW);
  const [createAnotherItem, setCreateAnotherItem] = useState(false);
  const numberOrPrivate = hasPrivateParentItem
    ? `${draftNameTag.toLowerCase()} item`
    : styleItemNumber(number);
  const title = isOption ? `Add options for ${numberOrPrivate}: ${name}` : 'Create New Item';
  const showVisibilityToggle =
    openTab === NEW && !isScenario && visibilitySetting && setVisibilitySetting;

  const sendAnalytics = useSendAnalytics();

  const triggeredBrand = brandAssistExperiment
    ? CheckBrandTriggers(item.name || '', item.description || '')
    : undefined;

  if (!open) return null;

  const showNewTabContent = openTab === NEW || isScenario || !convertItem;
  const content = showNewTabContent ? (
    <div>
      {loading && (
        <div className={classes.loading}>
          <LinearProgress hidden={!loading} />
        </div>
      )}
      <DialogContent className={classes.dialogContent}>
        <DialogsNewItemDetails
          key={count}
          hasParentItem={isOption}
          isScenario={isScenario}
          item={item}
          onChange={onChange}
          onSubmit={() => {
            const onCloseVar = createAnotherItem ? undefined : onClose;
            onSubmit(onCloseVar, true, triggeredBrand);
          }}
          parentItemID={parentItem?.id}
          parentItemIsDraft={isPrivateVisibility(parentItem?.visibility)}
        />
        <DialogsNewItemCategories categories={categories} onChange={onChange} />
      </DialogContent>
    </div>
  ) : (
    !!parentItem && <DialogsNewItemSearch convertItem={convertItem} parent={parentItem} />
  );

  const newItemButtons = (
    <div className={classes.buttonsContainer}>
      <div className={classes.buttonContainer}>
        {showVisibilityToggle && (
          <ButtonScales
            data-cy="create-draft-item-button"
            isDisabled={!isValidName}
            label="Create as Draft"
            onClick={() => {
              const visibility = Visibility.PRIVATE_DRAFT;
              setItem({ ...item, visibility });
              const onCloseVar = createAnotherItem ? undefined : onClose;
              onSubmit(onCloseVar, true, triggeredBrand);
            }}
            type="secondary"
          />
        )}
      </div>
      <div className={classes.buttonContainer}>
        {!hasPrivateParentItem && (
          <ButtonScales
            data-cy="create-published-item-button"
            isDisabled={!isValidName}
            label={isScenario ? 'Create' : 'Create and Publish'}
            onClick={() => {
              const onCloseVar = createAnotherItem ? undefined : onClose;
              onSubmit(onCloseVar, undefined, triggeredBrand);
            }}
            startIcon={!isScenario && <Check />}
            type="primary"
          />
        )}
      </div>
    </div>
  );

  const existingItemButtons = (
    <div className={classes.buttonsContainer}>
      <Button
        data-cy="add-existing-item-done-button"
        label="Done"
        onClick={onClose}
        type="primary"
      />
    </div>
  );

  const buttons = openTab === NEW ? newItemButtons : existingItemButtons;
  const footerAddMore = openTab === NEW && (
    <Checkbox
      data-cy="checkbox-createAnother"
      isSelected={createAnotherItem}
      onChange={(event: boolean) => {
        setCreateAnotherItem(event);
        sendAnalytics(
          newItemEvent(NewItemEvent.CREATE_ANOTHER, { anotherItem: event ? 'yes' : 'no' })
        );
      }}
    >
      <div className="type-body2">{`Create another ${isOption ? 'Option' : 'Item'}`}</div>
    </Checkbox>
  );

  const footerContent = (
    <div>
      <div
        className={`${classes.action} ${classes.actionFlex} ${
          openTab !== NEW && classes.contentEnd
        }`}
      >
        {footerAddMore}
        {buttons}
      </div>
    </div>
  );

  const subtitle = isConvertingToIWO && activeEstimate && (
    <div className={classes.subtitle}>
      <Typography data-cy="input-itemOptionTitle">
        {`The current estimate of ${generateCostImpactInfo(
          cost
        )} will be moved to Option ${styleItemNumber(
          number
        )}.1 when you create Option ${styleItemNumber(number)}.2 below.`}
      </Typography>
    </div>
  );
  const shareSettingsCalloutStyles = `px-4 pt-2 ${openTab === EXISTING && 'pb-2'}`;
  return (
    <div className={classes.dialogOverlay}>
      <div
        className={classes.dialogPaperDialogsNewItem}
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            onClose();
            onCloseNoChange?.();
            e.preventDefault();
            e.stopPropagation();
          }
        }}
      >
        {isScenario && <div className="h-2" style={{ background: scenarioProps?.color }} />}
        <div
          className={`${parentItem ? classes.tabTitle : ''} ${
            !parentItem ? classes.titleBorderBottom : ''
          } ${classes.titleContainer}`}
          data-cy="input-itemOptionTitle"
        >
          <Typography variant="title">{`${title} ${isScenario ? 'in Scenario' : ''}`}</Typography>
          <IconButton
            data-cy="DialogsNewItem-close"
            onClick={() => {
              onClose();
              onCloseNoChange?.();
            }}
            title="Close dialog"
          >
            <Close />
          </IconButton>
        </div>
        {subtitle}
        {parentItem && (
          <Tabs
            classes={{ root: classes.dialogTabs }}
            onChange={(_, v) => {
              sendAnalytics(optionsDialogTabAnalytics({ itemId: id || '', whichTab: v }));
              setOpenTab(v);
            }}
            value={openTab}
          >
            <Tab classes={{ label: classes.tabs }} data-cy="tab-new" label="New" value={NEW} />
            <Tab
              classes={{ label: classes.tabs }}
              data-cy="tab-choseExisting"
              label="Find Existing"
              value={EXISTING}
            />
          </Tabs>
        )}
        {hasPrivateParentItem && (
          <div className={shareSettingsCalloutStyles}>
            <Callout isFullWidth>
              <Lightbulb />
              <div className="inline type-label">
                Tip: Draft options will inherit the share settings of the draft item it&apos;s added
                to.
              </div>
            </Callout>
          </div>
        )}
        {isScenario && (
          <div className={shareSettingsCalloutStyles}>
            <Callout isFullWidth>
              <Lightbulb />
              <div className="inline type-label">
                Tip: Items created in a scenario are only visible in a scenario and do not appear
                anywhere else.
              </div>
            </Callout>
          </div>
        )}
        {content}
        {footerContent}
      </div>
    </div>
  );
};

export default withStyles(DialogsStyles)(DialogsNewItemContent);
