import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { UNGROUPED } from '../../constants';
import { PermissionResource } from '../../generated/graphql';
import {
  calculateSelectedItemsAndOptionsListsTree,
  getSelectedDraftItems,
} from '../../utilities/items';
import { useShouldDisplayCosts } from '../../utilities/permissions/useShouldDisplayCosts';
import { PartialCategory } from '../../utilities/permissions/utils';
import { mapOrder } from '../../utilities/utilities';
import { FilterManager } from '../FilterPanel/filterUtils';
import { CollapseAll, ExpandAll } from '../Icons/ExpandCollapse';
import { ScrollUp } from '../Icons/ScrollUp';
import { isPrivateVisibility } from '../Items/ItemsUtils';
import { useMilestonesQuery } from '../Milestones/hooks';
import { isScheduleSettingsDisabled } from '../ProjectProperties/ProjectScheduleImpact/ProjectScheduleImpactSettings';
import IconMenuIcon from '../Select/SelectMenu/IconMenuIcon';
import { SelectVariants } from '../Select/SelectMenu/SelectStyles';
import CheckboxContainer from '../shared-widgets/CheckboxContainer';
import useMemoWrapper from '../useMemoWrapper';

import ItemsListBulkEditingPanel from './BulkEditingPanel/ItemsListBulkEditingPanel';
import { ItemsListSettingsManager } from './ItemsListSettingsUtils';
import ItemsListStickyHeaderStyles from './ItemsListStickyHeaderStyles';
import ItemsListSummary from './ItemsListSummary';
import { VisibleItemInformation } from './ItemsListUtils';
import { COMPACT_LIST } from './ItemsListViewToggle';
import ShareItemButton from './ItemsSharing/ShareItemButton/ShareItemButton';

type ItemsListStickyHeaderProps = {
  canEdit: (resource: PermissionResource, args?: { trades: PartialCategory[] }) => boolean;
  classes: Classes<typeof ItemsListStickyHeaderStyles>;
  collapseAll: () => void;
  expandAll: () => void;
  filterManager: FilterManager;
  isCollapsed: boolean;
  isExpandToggleVisible: boolean;
  itemsCount: { visibleCount: number; selectedCount: number };
  itemsLikeIDsSelected: UUID[];
  itemsList?: ItemsListItem[];
  itemsTree: ItemsTree;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  loading: boolean;
  selectAllVisibleItemsLike: (checkAll: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  setMilestoneIDsSelected: any;
  settingsManager: ItemsListSettingsManager;
  visibleItemsInfo: VisibleItemInformation;
};

const ItemsListStickyHeader: FC<ItemsListStickyHeaderProps> = ({
  canEdit,
  classes,
  collapseAll,
  expandAll,
  filterManager,
  isCollapsed,
  isExpandToggleVisible,
  itemsCount,
  itemsLikeIDsSelected,
  itemsList: itemsListRaw,
  itemsTree,
  loading,
  selectAllVisibleItemsLike,
  setMilestoneIDsSelected,
  settingsManager,
  visibleItemsInfo,
}) => {
  const itemsList = useMemoWrapper(mapOrder<ItemsListItem>, itemsListRaw, itemsTree.orderedItemIDs);
  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID not found');

  const { settings } = settingsManager;
  const isScheduleImpactEnabled = !isScheduleSettingsDisabled();

  // HOOKS
  const [toggleCollapsed, setToggleCollapsed] = useState(isCollapsed);
  const [showScrollArrow, setShowScroll] = useState(false);

  const selectedDraftItems = useMemoWrapper(
    getSelectedDraftItems,
    itemsList ?? [],
    itemsLikeIDsSelected
  );
  const isSomeDraftItemsSelected = !!selectedDraftItems?.length;

  // DATA
  const { data: { milestones = [] } = {} } = useMilestonesQuery(projectId, false);

  // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  useEffect(() => {
    if (settings.expand.length === 0 && settings.collapse[0] === UNGROUPED)
      setToggleCollapsed(true);
  });

  const canViewCheckbox =
    canEdit(PermissionResource.ITEM_STATUS) ||
    canEdit(PermissionResource.ITEM_ASSIGNEES) ||
    canEdit(PermissionResource.ITEM_MILESTONE_AND_MEETINGS) ||
    canEdit(PermissionResource.ITEM_CATEGORIES);
  const { shouldDisplayCosts } = useShouldDisplayCosts();

  // FUNCTIONS

  const checkScrollingPosition = () => {
    if (!showScrollArrow && window.pageYOffset > 200) {
      setShowScroll(true);
    } else if (showScrollArrow && window.pageYOffset <= 200) {
      setShowScroll(false);
    }
  };

  window.addEventListener('scroll', checkScrollingPosition);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const setToggle = () => {
    setToggleCollapsed(!toggleCollapsed);
    if (toggleCollapsed) {
      expandAll();
    } else {
      collapseAll();
    }
  };

  const toggle = () =>
    toggleCollapsed ? (
      <IconButton
        classes={{ root: classes.buttonHover }}
        className={classes.hidePrint}
        data-cy="groupByBuckets-expandAll"
        onClick={setToggle}
        title="Expand All"
      >
        <ExpandAll classes={{ root: classes.iconSize }} />
      </IconButton>
    ) : (
      <IconButton
        classes={{ root: classes.buttonHover }}
        className={classes.hidePrint}
        data-cy="groupByBuckets-collapseAll"
        onClick={setToggle}
        title="Collapse All"
      >
        <CollapseAll className={`${classes.iconSize} ${classes.hidePrint}`} />
      </IconButton>
    );

  const isCompact = settings.view === COMPACT_LIST;

  const selectedItemLikeList = useMemoWrapper(
    calculateSelectedItemsAndOptionsListsTree,
    itemsList ?? [],
    itemsLikeIDsSelected
  );
  const isAllShareableDrafts = selectedItemLikeList.every(
    (it) =>
      isPrivateVisibility(it.visibility) &&
      (!it.parent || !isPrivateVisibility(it.parent.visibility ?? undefined))
  );

  return (
    <div
      className={`flex min-h-[50px] items-center gap-1 bg-background-primary p-0 pl-2 ${
        !isExpandToggleVisible ? 'pl-2.5' : ''
      } ${showScrollArrow ? 'pr-2.5' : ''}`}
    >
      {isExpandToggleVisible && toggle()}
      {canViewCheckbox && (
        <CheckboxContainer
          itemsCount={itemsCount}
          selectAllVisibleItems={selectAllVisibleItemsLike}
          settings={settings}
        />
      )}
      <ItemsListSummary
        filterManager={filterManager}
        itemsLikeIDsSelectedCount={itemsLikeIDsSelected.length}
        loading={loading}
        settingsManager={settingsManager}
        shouldDisplayCosts={shouldDisplayCosts}
        visibleItemsInfo={visibleItemsInfo}
      />

      <div className={`ml-2 flex gap-2 ${classes.hidePrint}`}>
        {itemsLikeIDsSelected.length !== 0 && (
          <ItemsListBulkEditingPanel
            canEdit={canEdit}
            hasDraftItems={isSomeDraftItemsSelected}
            itemsLikeIDsSelected={itemsLikeIDsSelected}
            itemsList={itemsList}
            milestones={milestones.filter((m: Milestone) => !m.isDraft)}
            setMilestoneIDsSelected={setMilestoneIDsSelected}
          />
        )}
        {itemsLikeIDsSelected.length !== 0 && isAllShareableDrafts && (
          <ShareItemButton
            canEdit={canEdit}
            isDisabled={false}
            itemIDs={itemsLikeIDsSelected}
            items={selectedDraftItems}
          />
        )}
      </div>
      <div className="ml-auto flex flex-shrink-0 basis-32 justify-end">
        {shouldDisplayCosts && <p className="text-right type-table-header">Cost Impact</p>}
      </div>
      <div />
      {isScheduleImpactEnabled && (
        <div className="flex flex-shrink-0 basis-32 justify-end">
          <p className="text-right type-table-header">Schedule Impact</p>
        </div>
      )}
      <div
        className={`flex flex-shrink-0 flex-wrap items-center justify-end ${
          isCompact ? 'basis-14' : 'basis-36'
        }`}
      >
        {showScrollArrow && (
          <IconMenuIcon
            handleClick={scrollToTop}
            icon={<ScrollUp className={classes.iconSize} />}
            iconName="scrollup"
            variant={SelectVariants.MENU}
          />
        )}
      </div>
    </div>
  );
};

export default withStyles(ItemsListStickyHeaderStyles)(ItemsListStickyHeader);
