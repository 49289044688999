import queryString from 'query-string';
import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { useReactiveVar } from '@apollo/client';
import { Divider, Typography } from '@material-ui/core';

import {
  ItemsListEvent,
  exportItemsListAnalytics,
  itemsListEvent,
  printItemsListAnalytics,
  printItemsListAndDetailsAnalytics,
  reportItemsList,
  setCollapseAnalytics,
} from '../../analytics/analyticsEventProperties';
import {
  isDownloadingItemsListToExcelVar,
  itemSidebarOpenVar,
  mountedItemsVar,
  newItemDialogOpenVar,
} from '../../api/apollo/reactiveVars';
import { TimelineActivityType } from '../../api/gqlEnumsBe';
import JoinAPI from '../../api/joinAPI';
import {
  ACCEPTED,
  ACTIVITY_ID,
  ALL_ACTIVITIES,
  ALL_MILESTONES,
  CATEGORIZATION_LIST,
  CREATOR,
  ITEM,
  ITEM_WITH_OPTIONS,
  PENDING,
  REJECTED,
  RUNNING,
  TARGET,
  UNGROUPED,
  VIEW_FILTER,
  VIEW_MODE_LIST,
} from '../../constants';
import { PermissionResource } from '../../generated/graphql';
import { useListKeyPressListener } from '../../hooks/useListKeyPressListener';
import useSendAnalytics from '../../hooks/useSendAnalytics';
import { RouteKeys } from '../../routes/paths';
import { withStyles } from '../../theme/komodo-mui-theme';
import { compareCost } from '../../utilities/compare';
import { checkCostModeIncludesMarkups, useCostMode } from '../../utilities/costMode';
import { useShouldDisplayCosts } from '../../utilities/permissions/useShouldDisplayCosts';
import { PartialCategory } from '../../utilities/permissions/utils';
import { generateSharedPath } from '../../utilities/routes/links';
import { isNonNullable } from '../../utilities/types';
import { useSetCollapse } from '../../utilities/urlState';
import {
  FilterManager,
  getQueryObjectFromCategorizationCategoryMap,
} from '../FilterPanel/filterUtils';
import ItemSidebarWrapper from '../frame/ItemSidebar/ItemSidebarWrapper';
import ItemsListHeadersLabel from '../Items/ItemsListHeaders/ItemsListHeadersLabel';
import ItemsListItemData from '../Items/ItemsListItem/ItemsListItemData';
import ItemsNestingCollapse from '../Items/ItemsNesting/ItemsNestingCollapse';
import ItemsNestingList from '../Items/ItemsNesting/ItemsNestingList';
import ItemsOptionsList from '../Items/ItemsOptionsList/ItemsOptionsList';
import MilestoneReportInlineLink from '../Milestones/MilestoneReportInlineLink';
import MilestoneReportLink from '../Milestones/MilestoneReportLink';
import { ProjectTermStore } from '../ProjectDisplaySettings/TerminologyProvider';
import ReportsManagerMenu from '../ReportsTab/ReportsManagerMenu/ReportsManagerMenu';
import { Button, ScrollContainer, Select } from '../scales';
import UploadFileSVG from '../shared-widgets/AddUploadDrag/AddUploadDragSVG';
import { useTimelineActivityOptionsQuery } from '../Timeline/hooks/TimelineHook';
import ZeroState from '../ZeroState/ZeroState';

import IfVisible from './IfVisible';
import ImportItemsAreaData from './ImportItems/ImportItemsAreaData';
import ItemsListExportPanel from './ItemsListExportPanel';
import ItemsListFilterPanel from './ItemsListFilterPanel';
import ItemsListImportButton from './ItemsListImportButton';
import ItemsListNewItemButton from './ItemsListNewItemButton';
import { ItemsListSettingsManager } from './ItemsListSettingsUtils';
import ItemListSortBar from './ItemsListSortBar';
import ItemsListStickyHeader from './ItemsListStickyHeader';
import styles from './ItemsListStyles';
import {
  CONTINGENCY_DRAW_FILTER_KEY,
  ITEMSLIST_DEFAULTS,
  VisibleItemInformation,
  formatSettingsForExport,
  generateEmptyMessage,
  getActivitySelectEntries,
  getBranchDisplayName,
  getCollapse,
} from './ItemsListUtils';
import ItemsListViewToggle, { COMPACT_LIST } from './ItemsListViewToggle';

type ItemsListProps = {
  activeMilestoneID: UUID;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  categorizations: any;
  classes: Classes<typeof styles>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  creators: any;
  filterManager: FilterManager;
  filteredBucketId?: UUID;
  filteredMilestoneID: UUID;
  isCollapsed: boolean;
  itemIds: UUID[];
  itemsList: ItemsListItem[];
  refetchItemsList: (isCostMutated?: boolean) => void;
  refetchItemsTree: () => void;
  itemsListMap: Map<UUID, ItemsListItem>;
  itemsTree: ItemsTree;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  loading: boolean;
  milestones: Milestone[];
  projectName: string;
  settingsManager: ItemsListSettingsManager;
  sharedUsersMap: Map<UUID, Pick<User, 'id' | 'name'>[]>;
  variant?: string;
  visibleItemsInfo: VisibleItemInformation;
  canAdd: (resource: PermissionResource, args?: { trades: PartialCategory[] }) => boolean;
  canEdit: (resource: PermissionResource, args?: { trades: PartialCategory[] }) => boolean;
  canView: (resource: PermissionResource, args?: { trades: PartialCategory[] }) => boolean;
  isViewOnly: boolean;
};

const ItemsList: FC<ItemsListProps> = ({
  activeMilestoneID,
  categorizations,
  classes,
  creators,
  filterManager,
  filteredMilestoneID,
  isCollapsed,
  itemIds,
  itemsList,
  refetchItemsList,
  refetchItemsTree,
  itemsListMap,
  itemsTree,
  loading = false,
  milestones,
  projectName,
  settingsManager,
  sharedUsersMap,
  variant,
  visibleItemsInfo,
  canAdd,
  canEdit,
  canView,
  isViewOnly,
}) => {
  const { settings, setSettings, persistAddedSettingsChanges } = settingsManager;
  const costMode = useCostMode();
  // HOOKS
  const sendAnalytics = useSendAnalytics();
  const t = useContext(ProjectTermStore);
  const isItemSidebarOpen = useReactiveVar(itemSidebarOpenVar);
  const newItemOpen = useReactiveVar(newItemDialogOpenVar);
  const setCollapse = useSetCollapse(settings, setSettings, setCollapseAnalytics);
  const { addCategories: addCategoriesToFilter, filters } = filterManager;
  const [itemsLikeIDsSelected, setItemsLikeIDsSelected] = useState([] as UUID[]);
  const [milestoneIDsSelected, setMilestoneIDsSelected] = useState([] as UUID[]);
  const visibleMilestoneIDs = visibleItemsInfo.milestoneIDs;
  const navigate = useNavigate();
  // Default mounted items on items list page load
  useEffectOnce(() => {
    mountedItemsVar(new Map<string, boolean>());
  });
  // Set already mounted item. We need to track already mounted items of virtualized list to apply cache policy
  const onUnmount = (id: UUID) => {
    if (!mountedItemsVar().get(id)) mountedItemsVar().set(id, true);
  };

  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID not found');
  const { data } = useTimelineActivityOptionsQuery({
    projectID: projectId,
    types: [],
    includeAllMilestones: true,
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  const activities = data?.timeline?.activities ?? [];
  const [activity, setActivity] = useState(
    settings.activityID ?? activities.find((a) => a.milestone?.id === filteredMilestoneID)?.id
  );

  // Effect used to update the activity selector when activities have been loaded
  const activityCount = useRef(activities.length);
  useEffect(() => {
    if (!activity && !settings.activityID && activities.length !== activityCount.current) {
      setActivity(activities.find((a) => a.milestone?.id === filteredMilestoneID)?.id);
    }
  }, [activities, activity, filteredMilestoneID, settings.activityID]);

  const isListWithOptions = useMemo(() => {
    if (itemsTree.items && itemsTree.items.length > 0) {
      return itemsTree.items.some((i) => (i.options || []).length);
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsTree, settings]);

  // sidebar navigation
  const { orderedItemIDs = [] } = itemsTree || {};
  const sortedItems =
    orderedItemIDs &&
    !loading &&
    orderedItemIDs
      .map((id: UUID) => {
        return itemsListMap.get(id);
      })
      .filter(isNonNullable);

  const selectItemLike = useCallback(
    (itemId: string, isSelected: boolean, isItem: boolean, milestoneID: UUID) => {
      let newSelectedItems = [...itemsLikeIDsSelected];
      let newSelectedMilestoneIDs = [...milestoneIDsSelected];

      if (!isSelected) {
        newSelectedItems = newSelectedItems.filter((i: string) => i !== itemId);
        newSelectedMilestoneIDs = newSelectedMilestoneIDs.filter((i: string) => i !== milestoneID);
      } else if (isItem) {
        newSelectedItems.unshift(itemId); // add items in the front of the selected items list
        newSelectedMilestoneIDs.unshift(milestoneID);
      } else {
        newSelectedItems.push(itemId);
        newSelectedMilestoneIDs.push(milestoneID);
      }
      setMilestoneIDsSelected(newSelectedMilestoneIDs);
      return setItemsLikeIDsSelected(newSelectedItems);
    },
    [itemsLikeIDsSelected, milestoneIDsSelected]
  );

  const selectAllVisibleItemsLike = useCallback(
    (checkAll: boolean) => {
      if (checkAll) {
        setItemsLikeIDsSelected(itemsTree.orderedItemIDs);
        setMilestoneIDsSelected(visibleMilestoneIDs);
      } else {
        setItemsLikeIDsSelected([]);
        setMilestoneIDsSelected([]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
    [itemIds, visibleMilestoneIDs]
  );

  // FUNCTIONS
  const setCurrentMilestone = (milestoneID?: string, bucketId?: string, activityID?: string) => {
    const currentMilestone = [milestoneID, bucketId].filter(isNonNullable);
    sendAnalytics(reportItemsList(milestoneID, 'milestone', settings));
    setSettings({
      currentMilestone,
      [ACTIVITY_ID]: !activityID || activityID === ALL_ACTIVITIES ? null : activityID,
      [CREATOR]: ITEMSLIST_DEFAULTS[CREATOR],
      [CONTINGENCY_DRAW_FILTER_KEY]: ITEMSLIST_DEFAULTS[CONTINGENCY_DRAW_FILTER_KEY],
    });
  };
  const expandAll = () => {
    sendAnalytics(setCollapseAnalytics(false, [UNGROUPED], 'all'));
    setSettings({ collapse: [], expand: [] });
  };
  const collapseAll = () => {
    sendAnalytics(setCollapseAnalytics(true, [UNGROUPED], 'all'));
    setSettings({ collapse: [UNGROUPED], expand: [] });
  };

  const onActivityFilterAnalytics = (activity: { type: TimelineActivityType } | undefined) => {
    if (!activity) return;
    sendAnalytics(
      itemsListEvent(ItemsListEvent.ACTIVITY_FILTER, {
        activityType: TimelineActivityType[activity.type],
      })
    );
  };

  // CONSTANTS
  const { collapse, groupBy, expand, show, status, view } = settings;
  const milestone = milestones.find((m: Milestone) => m.id === filteredMilestoneID);
  useEffect(() => {
    if (projectName) document.title = `${projectName} - Items`;
  }, [projectName]);
  const displayItemsAndOptions = show === ITEM_WITH_OPTIONS;
  const hasGroupings = !!variant;
  const isCategorizationList = variant === CATEGORIZATION_LIST;
  const columns = [
    'PENDINGADDS',
    'PENDINGDEDUCTS',
    'REJECTED',
    'ACCEPTED',
    'RUNNINGTOTAL',
    'TARGET',
  ];
  const terms = {
    expanded: !collapse.includes(UNGROUPED), // equivalent to not-collapse-all
    groupBy,
    status,
    [VIEW_FILTER]: settings[VIEW_FILTER],
    viewMode: VIEW_MODE_LIST,
    columns,
  };
  const search = `?${queryString.stringify(terms, { arrayFormat: 'index' })}`;
  const itemsCount = { selectedCount: itemsLikeIDsSelected.length, visibleCount: itemIds.length };

  const [showFilterPanel, setShowFilterPanel] = useState(false);
  useEffect(() => {
    if (!showFilterPanel) persistAddedSettingsChanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [showFilterPanel]);

  // Permissions
  const canViewMarkups =
    canView(PermissionResource.MARKUPS) && checkCostModeIncludesMarkups(costMode);
  const { shouldDisplayCosts } = useShouldDisplayCosts();

  // Print click and key press
  const printList = () => {
    sendAnalytics(printItemsListAnalytics(filteredMilestoneID, settings));
    window.open(
      generateSharedPath(RouteKeys.PRINT_PROJECT_ITEMS_LIST, {
        projectId,
        search: window.location.search,
      }),
      '_blank'
    );
  };

  const printListAndItemDetails = (withHistory: boolean) => {
    sendAnalytics(printItemsListAndDetailsAnalytics(filteredMilestoneID, settings));
    const search = withHistory ? '?withHistory=true' : undefined;
    window.open(
      generateSharedPath(RouteKeys.PRINT_PROJECT_ITEMS_LIST_DETAILS, { projectId, search }),
      '_blank'
    );
  };

  // Key Events
  useListKeyPressListener(printList);

  const listContainerRef = useRef(null);

  // GENERATORS
  // The renderItemOptions function is passed to nesting lists to produce the Options Lists for IWO in the items list.
  // It relies on settings from the items list to display in this useMemo
  const renderItemOptions = (
    it: ItemsTreeItem,
    spacerCount: number,
    showCategories: boolean,
    key?: string
  ) => {
    const { itemLikeID, cost } = it as ItemsTreeItem;
    const isNewItemsList = !!itemLikeID;

    const item = itemsListMap.get(itemLikeID);
    // may happen during async load of queries
    if (!item) return null;

    // if cost or item cost is null then we don't need to show this
    const shouldShowAcrossAllCategories = cost && item.cost ? !compareCost(cost, item.cost) : false;

    const mainCostImpact = isNewItemsList ? cost || item.cost : item.cost;
    const totalCostImpact =
      isNewItemsList && !shouldShowAcrossAllCategories ? undefined : item.cost;

    const { id } = item;
    const isNested = displayItemsAndOptions && !!it.options.length;
    const isSelected = itemsLikeIDsSelected.some((i) => i === id);
    const generateNestedList = () => {
      // this needs to be so complex because contributions across many branches
      const nodeId = `${id} ${key}`;
      const collapsed = getCollapse(nodeId, collapse, expand);
      const node = { id, item, options: it.options };
      const collapseProps = {
        collapsed,
        filteredMilestoneID,
        isSelected,
        node,
        nodeId, // this is important for item collapse distinction
        sharedUsersMap,
        selectItemLike,
        setCollapse,
        showCategories,
        spacerCount,
        variant: ITEM,
        view,
        mainCostImpact,
        totalCostImpact,
      };

      return (
        <IfVisible
          key={`${id} list`}
          heightEstimate={80}
          id={id}
          onUnmount={onUnmount}
          scrollContainerRef={listContainerRef}
        >
          <ItemsNestingCollapse
            key={`${key} ${JSON.stringify(id)} options`}
            refetchItemsTree={refetchItemsTree}
            {...collapseProps}
          >
            <ItemsOptionsList
              filteredMilestoneID={filteredMilestoneID}
              getOption={(o) => itemsListMap.get((o as ItemsTreeOption).itemLikeID)}
              isNested
              item={item}
              itemsLikeSelected={itemsLikeIDsSelected}
              nodeKey={key}
              options={it.options}
              projectId={projectId || ''}
              selectItemLike={selectItemLike}
              sharedUsersMap={sharedUsersMap}
              showCategories={showCategories}
              view={view}
            />
          </ItemsNestingCollapse>
        </IfVisible>
      );
    };

    return isNested ? (
      generateNestedList()
    ) : (
      <IfVisible
        key={`${id} item`}
        heightEstimate={80}
        id={id}
        onUnmount={onUnmount}
        scrollContainerRef={listContainerRef}
      >
        <ItemsListItemData
          addCategoriesToFilter={addCategoriesToFilter}
          filteredMilestoneID={filteredMilestoneID}
          isListWithOptions={isListWithOptions}
          isNested={isNested}
          isSelected={isSelected}
          item={item}
          link={generateSharedPath(RouteKeys.PROJECT_ITEMS_ITEM, { projectId, itemId: id })}
          mainCostImpact={mainCostImpact}
          options={(displayItemsAndOptions && it.options) || undefined}
          selectItemLike={selectItemLike}
          sharedUsers={sharedUsersMap.get(id) ?? []}
          showCategories={showCategories}
          showNoEstimate
          showParent
          totalCostImpact={totalCostImpact}
          view={view}
        />
      </IfVisible>
    );
  };

  const hasItemsWithOptions = itemsTree.items.some((item) => Boolean(item.options.length));
  const hasEmptyTrees = !itemsTree.items.length && !itemsTree.branches.length;
  const showIWOGroupings = displayItemsAndOptions && hasItemsWithOptions;
  const hasGroupedItems = hasGroupings && !hasEmptyTrees;
  const isExpandToggleVisible = showIWOGroupings || hasGroupedItems;
  const showMilestoneGroupings =
    showIWOGroupings || (hasGroupedItems && filteredMilestoneID !== ALL_MILESTONES);
  const showMilestoneCategorizationGrouping =
    isCategorizationList && !!milestones && milestones.length > 0;

  const milestoneName = milestone ? milestone.name : '';
  const fileNameTokens = [projectName, milestoneName];
  const exportList = () => {
    if (isDownloadingItemsListToExcelVar()) return;
    sendAnalytics(exportItemsListAnalytics(filteredMilestoneID, settings));
    JoinAPI.exportItemsList(
      orderedItemIDs,
      projectId,
      milestone ? milestone.id : null,
      getQueryObjectFromCategorizationCategoryMap(filters),
      formatSettingsForExport(settings, creators, categorizations).map(
        (line) => `${line.header}: ${line.value}`
      ),
      checkCostModeIncludesMarkups(costMode),
      fileNameTokens
    );
  };

  const [importFile, setImportFile] = useState<File | undefined>(undefined);
  const [importFileSource, setImportFileSource] = useState<FileSource | undefined>(undefined);

  const itemsUploadEl =
    !isViewOnly && !importFile ? (
      <div className="ml-auto mr-auto w-[550px]">
        <ImportItemsAreaData
          addFile={(file, source) => {
            setImportFile(file);
            setImportFileSource(source);
          }}
          header="Import Items"
          itemsCount={itemsCount.visibleCount}
          projectID={projectId}
          projectName={projectName}
        >
          <UploadFileSVG
            backgroundImage="url(/img/Import_Estimate/DESTINI2.svg)"
            svgClassName={classes.lowerEstimatePlatformIcon}
          />
        </ImportItemsAreaData>
      </div>
    ) : null;

  const emptyPlaceholder = !isViewOnly ? (
    <div className="flex h-full w-full flex-col content-center items-center justify-center border border-solid bg-background-primary">
      <Divider />
      {itemsUploadEl}
    </div>
  ) : (
    <div>
      <Divider />
      <Typography className={classes.emptyMessage}>{generateEmptyMessage({ status })}</Typography>
      {itemsUploadEl}
    </div>
  );

  return (
    <div className="flex h-full w-full">
      <div className="flex w-full grow flex-col text-type-primary">
        <div className="flex flex-wrap items-center gap-4 border-x bg-background-primary p-4">
          <header className="type-heading1">Items</header>
          <div className="flex items-center gap-2">
            <ItemsListViewToggle listView={view} setListView={(v) => setSettings({ view: v })} />
            <div className="min-w-[150px] print:hidden">
              <Select
                data-cy="activities-select"
                entries={getActivitySelectEntries(activities)}
                onChange={(id) => {
                  const activityData = activities.find((a) => a.id === id);
                  if (activityData?.milestone) {
                    setCurrentMilestone(activityData?.milestone.id || ALL_MILESTONES);
                    setActivity(id);
                  } else {
                    setCurrentMilestone(ALL_MILESTONES, undefined, id);
                    setActivity(id === ALL_ACTIVITIES ? undefined : id);
                  }
                  onActivityFilterAnalytics(activityData);
                }}
                value={activity ?? ALL_ACTIVITIES}
              />
            </div>
            {shouldDisplayCosts &&
              filteredMilestoneID &&
              filteredMilestoneID !== ALL_MILESTONES &&
              milestones &&
              milestones.length > 0 && <MilestoneReportLink milestoneId={filteredMilestoneID} />}
          </div>

          {/**
           * Dear Reader, consider whether you have time to convert these buttons to the Scales versions, or at
           * least remove the padding that they each have and convert it into a gap on the parent flex container.
           * Note that this impacts not just this component, but any component that uses the ReportsManagerMenu
           * and the FilterPanel components.
           *
           * Note that there is a FilterPopover component that is intended to be a Design-System replacement for
           * the existing FilterPanel component.
           * */}
          <div className="ml-auto flex">
            <Button
              data-cy="item-activity-feed-button"
              label="Item Activity Feed"
              onClick={() => {
                navigate(generateSharedPath(RouteKeys.PROJECT_ITEMS_ACTIVITY, { projectId }));
              }}
              type="tertiary"
            />
            <ItemsListFilterPanel
              creators={creators}
              filterManager={filterManager}
              setSettings={setSettings}
              setShowFilterPanel={setShowFilterPanel}
              settings={settings}
              showContingencies={canViewMarkups}
              showFilterPanel={showFilterPanel}
            />
            <ReportsManagerMenu
              isViewOnly={isViewOnly}
              setSettings={setSettings}
              settings={settings}
              variant="itemsList"
            />
            {!loading &&
              shouldDisplayCosts && ( // ensure activeMilestoneID is fetched
                <ItemsListImportButton
                  activeMilestoneID={activeMilestoneID}
                  className={classes.menuOutline}
                  file={importFile}
                  fileSource={importFileSource}
                  isViewOnly={isViewOnly}
                  itemsCount={itemsCount.visibleCount}
                  milestones={milestones}
                  onClose={() => setImportFile(undefined)}
                  projectName={projectName}
                />
              )}
            <ItemsListExportPanel
              exportList={exportList}
              printDetails={printListAndItemDetails}
              printList={printList}
            />
            {canAdd(PermissionResource.ITEM_DETAILS) && (
              <div className="ml-2">
                <ItemsListNewItemButton milestoneID={activeMilestoneID} />
              </div>
            )}
          </div>
        </div>
        <ItemListSortBar milestone={milestone} setSettings={setSettings} settings={settings} />
        <div className="flex grow flex-col overflow-x-auto">
          <div className="z-60 sticky top-0 min-w-[800px] border-x">
            <ItemsListStickyHeader
              canEdit={canEdit}
              collapseAll={collapseAll}
              expandAll={expandAll}
              filterManager={filterManager}
              isCollapsed={isCollapsed}
              isExpandToggleVisible={isExpandToggleVisible}
              itemsCount={itemsCount}
              itemsLikeIDsSelected={itemsLikeIDsSelected}
              itemsList={itemsList}
              itemsTree={itemsTree}
              loading={loading}
              selectAllVisibleItemsLike={selectAllVisibleItemsLike}
              setMilestoneIDsSelected={setMilestoneIDsSelected}
              settingsManager={settingsManager}
              visibleItemsInfo={visibleItemsInfo}
            />
            {showMilestoneCategorizationGrouping && (
              <div
                className={`mr-auto gap-1 ${isCategorizationList ? '' : classes.hidePrint} ${
                  !isCategorizationList ? '' : classes.header
                }`}
              >
                {shouldDisplayCosts && showMilestoneGroupings && (
                  <MilestoneReportInlineLink
                    milestoneId={filteredMilestoneID}
                    milestones={milestones}
                    search={search}
                  />
                )}
                <div className="ml-auto" />
                {shouldDisplayCosts && (
                  <>
                    <ItemsListHeadersLabel compress status={PENDING} />
                    <ItemsListHeadersLabel compress status={REJECTED} />
                    <ItemsListHeadersLabel compress status={ACCEPTED} />
                    <ItemsListHeadersLabel compress={false} status={RUNNING} />
                    <ItemsListHeadersLabel compress={false} status={TARGET} />
                  </>
                )}
                <div
                  className={`flex-shrink-0 ${view === COMPACT_LIST ? 'basis-14' : 'basis-36'}`}
                />
              </div>
            )}
          </div>
          <ScrollContainer
            ref={listContainerRef}
            className="z-50 w-full min-w-[800px] flex-grow overflow-y-auto"
            direction="vertical"
          >
            {loading && <ZeroState loading />}
            {!loading && hasEmptyTrees && !newItemOpen && emptyPlaceholder}
            {!loading && (
              <>
                {/* Ungrouped Items */}
                {itemsTree.items.map((item) =>
                  renderItemOptions(item, 0, true, `row-${item.itemLikeID}`)
                )}

                {/* Grouped Items */}
                {itemsTree.branches.map((branch) => {
                  if (!branch) return null;

                  return (
                    <ItemsNestingList
                      key={branch.key}
                      branches={branch.branches}
                      displayName={getBranchDisplayName(branch.displayName, t)}
                      items={branch.items}
                      itemsLikeIDsSelected={itemsLikeIDsSelected}
                      level={branch.level}
                      node={{
                        display: branch.displayName,
                        id: branch.key,
                        level: branch.level,
                        nodeCosts: branch.nodeCosts,
                      }}
                      pastLevel={itemsTree.level}
                      projectId={projectId}
                      refetchItemsTree={refetchItemsTree}
                      renderItemOptions={renderItemOptions}
                      selectItemLike={selectItemLike}
                      setCollapse={setCollapse}
                      settings={settings}
                      sharedUsersMap={sharedUsersMap}
                      showCategory={false}
                      topLevelItemCount={branch.items.length}
                      variant={variant}
                      view={view}
                    />
                  );
                })}
              </>
            )}
          </ScrollContainer>
        </div>
      </div>
      {isItemSidebarOpen && sortedItems && (
        <ItemSidebarWrapper
          items={sortedItems}
          milestones={milestones}
          onItemMutated={refetchItemsList}
        />
      )}
    </div>
  );
};

const ItemsListStyled = withStyles(styles)(ItemsList);

export default ItemsListStyled;
