import { FC, useCallback, useRef, useState } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { DateVisibilityToggle } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { ROW_CONTAINER_HEIGHT } from '../../Charts/ChartsAllMilestones/ChartsAllMilestonesStyles';
import { Button } from '../../scales';
import DialogsStyles, { DIALOG_PADDING } from '../DialogsStyles';

import DialogsHideList, { HideDate } from './DialogsHideList';

type DialogsHideProps = {
  classes: Classes<typeof DialogsStyles>;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  loading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onClose: any;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  open: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  renderChart: (height?: number, onClick?: (clicked: any) => void) => JSX.Element;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  selected: HideDate | any;
  setSelected: (selected: HideDate | null) => void;
  title: string;
  toggleFn: (toggles: DateVisibilityToggle[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  listItems: any;
};

const DialogsHide: FC<DialogsHideProps> = ({
  renderChart,
  classes,
  loading = true,
  onClose,
  open,
  selected,
  setSelected,
  title,
  toggleFn,
  listItems,
}) => {
  // Scroll the the currently highlighted event in the event list
  const listItemRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const scrollToRef = (refCurrent?: any) => {
    if (refCurrent) {
      refCurrent.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  // every time that selected changes, we can go to the ref
  const selectedRef = listItemRef && listItemRef.current;
  const onClick = useCallback(() => {
    if (selected && selectedRef) {
      scrollToRef(selectedRef);
    }
  }, [selected, selectedRef]);

  const onHover = (v: HideDate | null) => {
    if (selected !== v) {
      setSelected(v);
    }
  };

  const onMouseLeave = () => {
    if (selected) {
      setSelected(null);
    }
  };

  const getToggleFromSelected = (visible: boolean) => (hideDate: HideDate) => {
    const { point, milestone } = hideDate;
    const milestoneID = milestone.id;
    const { dateStart, dateEnd } = point;
    return {
      milestoneID,
      dateStart: dateStart.slice(0, 10),
      dateEnd: dateEnd.slice(0, 10),
      visible,
    };
  };

  const onClickToggleVisibility = () => {
    if (selected) {
      toggleFn([getToggleFromSelected(!selected.point.isVisible)(selected)]);
    }
  };

  const onClickToggleAll = (visible: boolean) => {
    if (listItems) {
      toggleFn(listItems.map(getToggleFromSelected(visible)));
    }
  };

  // determine the height we should make the trendline
  // because the way the graph library works we need to
  // give it an exact height, and we want the height
  // to be 50% of the dialog height for the user
  const [trendlineHeight, setTrendlineHeight] = useState(268);

  const adjustHeight = (container: HTMLDivElement) => {
    if (container) {
      const height = container.offsetHeight - ROW_CONTAINER_HEIGHT - 2 * DIALOG_PADDING;
      if (height !== trendlineHeight) setTrendlineHeight(height);
    }
  };

  const content = (
    <DialogContent className={classes.fullHeight}>
      <div className={classes.contentContainer}>
        <div ref={adjustHeight} className={classes.halfHeight}>
          <div className={classes.content}>{renderChart(trendlineHeight, onClick)}</div>
        </div>
        <div className={classes.halfHeight}>
          <DialogsHideList
            listItemRef={listItemRef}
            listItems={listItems}
            onClickToggleAll={onClickToggleAll}
            onClickToggleVisibility={onClickToggleVisibility}
            onMouseLeave={onMouseLeave}
            onMouseOver={onHover}
            selected={selected}
            unit="date"
          />
        </div>
      </div>
    </DialogContent>
  );

  return (
    <Dialog
      classes={{
        paper: classes.dialogPaperLarge,
      }}
      maxWidth={false}
      onClose={onClose}
      open={open}
    >
      <div className={classes.titleContainer}>
        <Typography variant="title">{title}</Typography>
        <IconButton onClick={onClose} title="Close dialog">
          <Close />
        </IconButton>
      </div>
      <Divider />
      <div className={classes.loading}>
        <LinearProgress className={classes.loading} hidden={!loading} />
      </div>
      {content}
      <Divider />
      <DialogActions className={classes.action}>
        <Button
          data-cy="done-editing-button"
          label="Done Editing"
          onClick={onClose}
          type="primary"
        />
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(DialogsStyles)(DialogsHide);
