import { useEffect, useState } from 'react';

import { RouteKeys } from '../../../routes/paths';
import { generateSharedPath } from '../../../utilities/routes/links';
import { isEnumValue } from '../../../utilities/types';
import { CategoryMultiSelect } from '../../dragon-scales';
import { FilterPopover } from '../../FilterPopover/FilterPopover';
import { ButtonBar, Icon, ScrollContainer, TextInput, TextLink } from '../../scales';
import { IdeaFilters, IdeaGroup as IdeaGroupType, ProjectIdea, StarredToggle } from '../types';

import { IdeasEventType, useSendIdeasAnalytics } from './hooks/analytics';
import IdeaTile from './IdeaTile';
import MilestoneSelect from './MilestoneSelect';
import NoResults from './NoResults';
import OptOutBanner from './OptOutBanner';

type Props = {
  filters: IdeaFilters;
  ideaGroups: IdeaGroupType[];
  ideas: ProjectIdea[];
  ideasForNoResults: ProjectIdea[];
  milestoneID: UUID | undefined;
  onChangeMilestone: (milestoneID: UUID) => void;
  onResetFilters: () => void;
  onStar: (ideaID: UUID, isStarred: boolean) => void;
  onUpdateFilters: (update: Partial<IdeaFilters>) => void;
  projectID: UUID;
  starredIdeas: UUID[];
};

export default function Ideas(props: Props) {
  const sendIdeasAnalytics = useSendIdeasAnalytics();

  const hasActiveSearch = Boolean(props.filters.search);
  const hasNoResults = hasActiveSearch ? !props.ideas.length : !props.ideaGroups.length;

  return (
    <div className="flex flex-col gap-6 p-6">
      <div className="flex flex-row items-center gap-4">
        <h1 className="type-heading1">Ideas</h1>
        <div className="min-w-[300px]">
          <MilestoneSelect
            onChange={props.onChangeMilestone}
            projectID={props.projectID}
            value={props.milestoneID}
          />
        </div>
        <TextLink
          label="View Milestone Estimate"
          onClick={() =>
            sendIdeasAnalytics(IdeasEventType.MILESTONE_ESTIMATE_LINK, {
              milestoneID: props.milestoneID,
            })
          }
          to={generateSharedPath(RouteKeys.PROJECT_MILESTONES_MILESTONE, {
            projectId: props.projectID,
            milestoneId: props.milestoneID,
            search: '?view=ESTIMATE',
          })}
        />
      </div>
      <OptOutBanner />
      <Filters filters={props.filters} onUpdate={props.onUpdateFilters} />
      {hasActiveSearch ? (
        <SearchResults
          ideas={props.ideas}
          onStar={props.onStar}
          projectID={props.projectID}
          search={props.filters.search}
          starredIdeas={props.starredIdeas}
        />
      ) : (
        <IdeaGroups
          ideaGroups={props.ideaGroups}
          onStar={props.onStar}
          projectID={props.projectID}
          starredIdeas={props.starredIdeas}
        />
      )}
      {hasNoResults && !!props.ideasForNoResults.length && (
        <>
          <NoResults
            filters={props.filters}
            hasNoStarredIdeas={!props.starredIdeas.length}
            onResetFilters={props.onResetFilters}
          />
          <IdeaGroup
            ideas={props.ideasForNoResults}
            name="Other ideas you might like"
            onStar={props.onStar}
            projectID={props.projectID}
            starredIdeas={props.starredIdeas}
          />
        </>
      )}
    </div>
  );
}

function Filters(props: {
  filters: IdeaFilters;
  onUpdate: (update: Partial<IdeaFilters>) => void;
}) {
  const sendIdeasAnalytics = useSendIdeasAnalytics();

  const numCategoryFilters = new Set(props.filters.categories.map((c) => c.categorizationID)).size;
  const categorizations = [
    { name: 'Masterformat', id: 'c22ed8a1-8fb3-43fc-b9ad-1ecf514c9852', builtin: true },
    { name: 'Uniformat', id: 'f7716c5d-5970-40b9-b215-03cc1b9773bf', builtin: true },
  ];

  return (
    <div className="flex gap-4">
      <TextInput
        aria-label="Search Input"
        onChange={(search) => {
          sendIdeasAnalytics(IdeasEventType.CHANGE_SEARCH, { search });
          props.onUpdate({ search });
        }}
        onClear={() => {
          sendIdeasAnalytics(IdeasEventType.RESET_SEARCH);
          props.onUpdate({ search: '' });
        }}
        placeholder="Search ideas by name or material"
        startAdornment={<Icon name="search" />}
        value={props.filters.search}
      />
      <ButtonBar
        onChange={(toggleValue) => {
          if (isEnumValue(StarredToggle, toggleValue)) {
            sendIdeasAnalytics(IdeasEventType.CHANGE_STAR_TOGGLE, { toggleValue });
            props.onUpdate({ toggleValue });
          }
        }}
        options={[
          { value: StarredToggle.ALL_IDEAS, label: 'All Ideas' },
          { value: StarredToggle.STARRED_IDEAS, label: 'Starred Ideas' },
        ]}
        value={props.filters.toggleValue}
      />
      <FilterPopover
        numFiltersApplied={numCategoryFilters}
        onResetFilters={() => {
          sendIdeasAnalytics(IdeasEventType.RESET_CATEGORY_FILTERS);
          props.onUpdate({ categories: [] });
        }}
      >
        {categorizations.map((categorization) => (
          <CategoryMultiSelect
            key={categorization.id}
            categorization={categorization}
            onChange={(categoryIDs) => {
              sendIdeasAnalytics(IdeasEventType.CHANGE_CATEGORY_FILTERS, {
                categorizationID: categorization.id,
                categoryIDs,
              });
              props.onUpdate({
                categories: [
                  ...props.filters.categories.filter(
                    (category) => category.categorizationID !== categorization.id
                  ),
                  ...categoryIDs.map((id) => ({ id, categorizationID: categorization.id })),
                ],
              });
            }}
            value={props.filters.categories.flatMap((category) =>
              category.categorizationID === categorization.id ? [category.id] : []
            )}
          />
        ))}
      </FilterPopover>
    </div>
  );
}

function IdeaGroups(props: {
  ideaGroups: IdeaGroupType[];
  onStar: (ideaID: UUID, isStarred: boolean) => void;
  projectID: UUID;
  starredIdeas: UUID[];
}) {
  if (!props.ideaGroups.length) return null;
  return (
    <>
      {props.ideaGroups.map(({ total, name, ideas }) => (
        <IdeaGroup
          key={name}
          count={ideas.length === total ? `(${ideas.length})` : `(${ideas.length} of ${total})`}
          ideas={ideas}
          name={name}
          onStar={props.onStar}
          projectID={props.projectID}
          starredIdeas={props.starredIdeas}
        />
      ))}
    </>
  );
}

function IdeaGroup(props: {
  count?: string;
  ideas: ProjectIdea[];
  name: string;
  onStar: (ideaID: UUID, isStarred: boolean) => void;
  projectID: UUID;
  starredIdeas: UUID[];
}) {
  const sendIdeasAnalytics = useSendIdeasAnalytics();

  const [hasScrolled, setHasScrolled] = useState(false);
  useEffect(() => {
    if (hasScrolled)
      sendIdeasAnalytics(IdeasEventType.SCROLL_IDEA_GROUP, { ideaGroup: props.name });
  }, [hasScrolled, props.name, sendIdeasAnalytics]);

  if (!props.ideas.length) return null;
  return (
    <div className="flex flex-col gap-4">
      <h2 className="type-heading2">{[props.name, props.count].join(' ')}</h2>
      <ScrollContainer direction="horizontal" onScroll={() => setHasScrolled(true)}>
        <div className="flex gap-6">
          {props.ideas.map((idea) => (
            <div key={idea.id} className="w-80">
              <IdeaTile
                idea={idea}
                isStarred={props.starredIdeas.includes(idea.id)}
                onClick={() =>
                  sendIdeasAnalytics(IdeasEventType.CLICK_IDEA_TILE, {
                    ideaGroup: props.name,
                    ideaID: idea.id,
                    ideaName: idea.name,
                    location: 'IdeaGroup',
                  })
                }
                onStar={(isStarred) => {
                  sendIdeasAnalytics(IdeasEventType.STAR_IDEA, {
                    ideaGroup: props.name,
                    ideaID: idea.id,
                    ideaName: idea.name,
                    isStarred,
                    location: 'IdeaGroup',
                  });
                  props.onStar(idea.id, isStarred);
                }}
                projectID={props.projectID}
              />
            </div>
          ))}
        </div>
      </ScrollContainer>
    </div>
  );
}

function SearchResults(props: {
  ideas: ProjectIdea[];
  onStar: (ideaID: UUID, isStarred: boolean) => void;
  projectID: UUID;
  search: string;
  starredIdeas: UUID[];
}) {
  const sendIdeasAnalytics = useSendIdeasAnalytics();

  if (!props.ideas.length) return null;
  return (
    <div className="flex flex-col gap-4">
      <h2 className="type-heading2">Results for &quot;{props.search}&quot;</h2>
      <div className="grid w-full grid-cols-[repeat(auto-fill,minmax(300px,1fr))] gap-6">
        {props.ideas.map((idea) => (
          <IdeaTile
            key={idea.id}
            idea={idea}
            isStarred={props.starredIdeas.includes(idea.id)}
            onClick={() =>
              sendIdeasAnalytics(IdeasEventType.CLICK_IDEA_TILE, {
                ideaID: idea.id,
                ideaName: idea.name,
                location: 'SearchResults',
              })
            }
            onStar={(isStarred) => {
              sendIdeasAnalytics(IdeasEventType.STAR_IDEA, {
                ideaID: idea.id,
                ideaName: idea.name,
                location: 'SearchResults',
                isStarred,
              });
              props.onStar(idea.id, isStarred);
            }}
            projectID={props.projectID}
          />
        ))}
      </div>
    </div>
  );
}
