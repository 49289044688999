import { Link } from 'react-router-dom';

import { RouteKeys } from '../../../routes/paths';
import { generateSharedPath } from '../../../utilities/routes/links';
import { Button, Chip, Icon, IconMenuButton } from '../../scales';
import Star from '../common/Star';
import { ProjectIdea } from '../types';

import CostComparison from './CostComparison';
import IdeaRelevance from './IdeaRelevance';
import IdeaTabs from './IdeaTabs';
import { AlternateTile, RootTile } from './Tile';

type Props = {
  canResetHiddenAlternates: boolean;
  idea: ProjectIdea;
  isStarred: boolean;
  onCreateItemFromAlternate: (alternateID: UUID) => void;
  onCreateItemFromIdea: () => void;
  onHideAlternate: (alternateID: UUID) => void;
  onResetHiddenAlternates?: () => void;
  onStar: (isStarred: boolean) => void;
  projectID: UUID;
};

export default function Idea(props: Props) {
  return (
    <div className="flex flex-col gap-6 py-6">
      <div className="flex flex-col gap-6 px-6">
        <IdeaHeader
          canResetHiddenAlternates={props.canResetHiddenAlternates}
          idea={props.idea}
          isCreateItemDisabled={!props.idea.alternates.length}
          isStarred={props.isStarred}
          onCreateItem={props.onCreateItemFromIdea}
          onResetHiddenAlternates={props.onResetHiddenAlternates}
          onStar={props.onStar}
          projectID={props.projectID}
        />
        <IdeaRelevance idea={props.idea} />
        <div className="flex flex-col gap-4">
          <h2 className="type-heading2">Overview</h2>
          <Tiles
            idea={props.idea}
            onCreateItem={props.onCreateItemFromAlternate}
            onHideAlternate={props.onHideAlternate}
          />
        </div>
        <div className="flex flex-col gap-4">
          <h2 className="type-heading2">Cost Comparison</h2>
          <CostComparison
            data={[
              {
                name: props.idea.root.name,
                min: props.idea.root.costRangeLow ?? 0,
                max: props.idea.root.costRangeHigh ?? 0,
                variant: 'primary',
              },
              ...props.idea.alternates.map((a) => ({
                name: a.name,
                min: a.costRangeLow ?? 0,
                max: a.costRangeHigh ?? 0,
              })),
            ]}
          />
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <h2 className="px-6 type-heading2">Details</h2>
        <IdeaTabs idea={props.idea} />
      </div>
    </div>
  );
}

function IdeaHeader(props: {
  canResetHiddenAlternates: boolean;
  idea: ProjectIdea;
  isCreateItemDisabled: boolean;
  isStarred: boolean;
  onCreateItem: () => void;
  onResetHiddenAlternates?: () => void;
  onStar: (isStarred: boolean) => void;
  projectID: UUID;
}) {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between gap-4">
        <h1 className="flex w-full max-w-max items-center gap-2 type-heading1">
          <Link
            className="text-type-link"
            to={generateSharedPath(RouteKeys.PROJECT_IDEAS, { projectId: props.projectID })}
          >
            Ideas
          </Link>
          <div>/</div>
          <div>{props.idea.name}</div>
          <Star aria-label="Star Idea" isSelected={props.isStarred} onChange={props.onStar} />
        </h1>
        <div className="flex gap-2">
          <Button
            isDisabled={props.isCreateItemDisabled}
            label="Add Idea To Project"
            onClick={() => props.onCreateItem()}
            type="primary"
          />
          <IconMenuButton
            aria-label="more actions button"
            entries={[
              {
                id: 'show',
                label: 'Show all Alternates',
                startAdornment: <Icon name="show" />,
                onClick: () => props.onResetHiddenAlternates?.(),
                disabled: !props.canResetHiddenAlternates,
              },
            ]}
            type="secondary"
          />
        </div>
      </div>
      <div className="flex gap-6">
        {props.idea.root.uniFormat && (
          <Category categorization="UniFormat" category={props.idea.root.uniFormat} />
        )}
        {props.idea.root.masterFormat && (
          <Category categorization="MasterFormat" category={props.idea.root.masterFormat} />
        )}
      </div>
    </div>
  );
}

function Category(props: { categorization: string; category: { name: string; number: string } }) {
  return (
    <div className="flex flex-col gap-0.5">
      <div className="type-label">{props.categorization}</div>
      <div className="flex flex-row items-center gap-2">
        <div className="line-clamp-1">{props.category.name}</div>
        <Chip text={props.category.number} />
      </div>
    </div>
  );
}

function Tiles(props: {
  idea: ProjectIdea;
  onCreateItem: (alternateID: UUID) => void;
  onHideAlternate: (alternateID: UUID) => void;
}) {
  return (
    <div className="grid w-full grid-cols-[repeat(auto-fill,minmax(310px,1fr))] gap-6">
      <RootTile material={props.idea.root} />
      {props.idea.alternates.map((alternate) => (
        <AlternateTile
          key={alternate.id}
          alternate={alternate}
          onCreateItem={() => props.onCreateItem(alternate.id)}
          onHide={() => props.onHideAlternate(alternate.id)}
        />
      ))}
    </div>
  );
}
