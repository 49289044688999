import { extent } from 'd3-array';
import { cloneDeep } from 'lodash';

import { DesignPhaseEnum, ProjectStatuses } from '../../api/gqlEnums';
import { ACTIVE_STATUS } from '../../constants';
import {
  Alerts,
  DesignPhaseType,
  EventType,
  InsightsActiveAlert,
  InsightsMilestone,
  InsightsProjectsBreakdownItem,
  InsightsSort,
  InsightsSortKey,
  ItemsTimelineWithEventsQuery,
  ProjectDeliveryType,
  SearchProjectsFilterOptionsQuery,
  SortDirection,
  Status,
} from '../../generated/graphql';
import { setAllDayRange } from '../../utilities/dates';
import { EMPTY_COST, renderPercentString } from '../../utilities/string';
import { isNonNullable } from '../../utilities/types';
import {
  ItemDueState,
  TimelineItemData,
} from '../dragon-scales/TimelineCharts/InsightsItems/types';
import { InsightsFilterState, ProjectFilterManager } from '../ProjectsList/ProjectsListUtils';

import { StackDataItem } from './Charts/utils';
import { HeaderDisplayBy } from './PieCharts/InsightsListHeaderPieBar';
import { InsightsTabId } from './types';

export type StatusEvent = ItemsTimelineWithEventsQuery['costTrendlineEvents']['events'][number];

export const INSIGHTS_DEFAULTS: InsightsFilterState = {
  deliveryMethods: [],
  estimateCostRange: {
    max: null,
    min: null,
  },
  gsfRange: {
    max: null,
    min: null,
  },
  statuses: [ACTIVE_STATUS],
  types: [],
  projectTypes: [],
  companies: [],
  locations: [],
  orgNodeIDs: [],
  milestoneDesignPhases: [],
  projectLeadIDs: [],
  designPhases: [],
};

export const INSIGHTS_COUNTS_DEFAULTS = {
  filteredProjects: 0,
  totalProjects: 0,
  totalAlerts: 0,
  projectsWithAlerts: 0,
  triggeredAlertsByProject: [],
  organizationBreakdowns: [],
  projectLeadsBreakdown: [],
  projectTypesBreakdown: [],
  designPhasesBreakdown: [],
};

export const getInitialFilterOptions = (
  filterOptionsData: SearchProjectsFilterOptionsQuery | undefined,
  designPhaseTypes: DesignPhaseType[],
  projectDeliveryTypes: ProjectDeliveryType[]
) => {
  if (!(filterOptionsData && designPhaseTypes && projectDeliveryTypes)) return undefined;
  const intialFilterOptions: SearchProjectsFilterOptions = cloneDeep(
    filterOptionsData.searchProjectsFilterOptions
  );
  intialFilterOptions.gsfRange = {
    max: null,
    min: null,
  };
  intialFilterOptions.estimateCostRange = {
    max: null,
    min: null,
  };
  intialFilterOptions.designPhases = designPhaseTypes.map((d) => d.name) || [];
  intialFilterOptions.deliveryMethods = projectDeliveryTypes.map((d) => d.name) || [];
  intialFilterOptions.types =
    filterOptionsData.searchProjectsFilterOptions.projectTypes?.map((type) => type.name) || [];

  return intialFilterOptions;
};

export const getInsightsInput = (
  companyID: UUID,
  filterManager: ProjectFilterManager,
  sortState: InsightsSort,
  alertsOnly: boolean
) => {
  return {
    companyID,
    filter: {
      deliveryTypes: filterManager.filterState?.deliveryMethods || [],
      gsfRange:
        typeof filterManager.filterState.gsfRange === 'string'
          ? {
              max: null,
              min: null,
            }
          : filterManager.filterState.gsfRange,
      statuses: filterManager.filterState?.statuses || [],
      types: filterManager.filterState?.types || [],
      companies: filterManager.filterState?.companies || [],
      estimateCostRange:
        typeof filterManager.filterState.estimateCostRange === 'string'
          ? {
              max: null,
              min: null,
            }
          : filterManager.filterState.estimateCostRange,
      locations: filterManager.filterState?.locations || [],
      orgNodeIDs: filterManager.filterState?.orgNodeIDs || [],
      milestoneDesignPhases: filterManager.filterState?.designPhases || [],
      projectLeadIDs: filterManager.filterState?.projectLeadIDs || [],
      alertsOnly,
    },
    sort: sortState,
  };
};

export const getDueDateState = (item: ItemsTimelineItem | ItemsListItem, todayDate: Date) => {
  if (item.status !== Status.PENDING) {
    return ItemDueState.Decided;
  }
  if (!item.dueDate) {
    return ItemDueState.Missing;
  }
  const dueDate = new Date(item.dueDate || '0');
  if (dueDate < todayDate) {
    return ItemDueState.PastDue;
  }
  return ItemDueState.Upcoming;
};

export const getDueDateStateNoDue = (event: StatusEvent | undefined) => {
  let status;
  if (event?.timestamp) {
    const { oldStatus, newStatus } = event.eventContent;
    // Past Bars
    if (
      // pending to anything
      oldStatus === Status.PENDING ||
      // accepted to rejected
      (oldStatus === Status.ACCEPTED && newStatus === Status.REJECTED) ||
      // rejected to accepted
      (oldStatus === Status.REJECTED && newStatus === Status.ACCEPTED)
    ) {
      status = ItemDueState.Decided;
    }

    // accepted to incorporated
    if (oldStatus === Status.ACCEPTED && newStatus === Status.INCORPORATED) {
      // no count
      status = null;
    }
  }
  return status;
};

export const computeItemDecidedDate = (
  list: ItemsTimelineItem[],
  today: string,
  events: EventsTimelineEvent[]
): TimelineItemData[] => {
  // Filter by CHANGE_STATUS event type
  const statusChangeEvents = events
    .filter(({ eventType }) => eventType === EventType.CHANGE_STATUS)
    // Recent events first, descending order
    .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
  const todayDate = new Date(today);
  todayDate.setDate(todayDate.getDate() - 1);
  // Since we are comparing dueDate < todayDate, it is important to set todayDate to the end of the day.
  setAllDayRange([new Date(today), todayDate]);

  const result = list
    .map((item: ItemsTimelineItem) => {
      const event = statusChangeEvents.find((event) => event.item?.id === item.id);
      // We are interested in the most recent event
      // Due state for items without due date
      const dueState = item.dueDate
        ? getDueDateState(item, todayDate)
        : getDueDateStateNoDue(event);
      if (dueState === null || dueState === undefined) return undefined;
      const useDueDate = dueState === ItemDueState.PastDue || dueState === ItemDueState.Upcoming;
      let dueDate = useDueDate ? item.dueDate || event?.timestamp : event?.timestamp;

      // When the event is null, set the due date to the created date for decided items
      if (!dueDate && dueState === ItemDueState.Decided && !event) {
        dueDate = item.createdAt;
      }

      if (!dueDate) return undefined;
      return {
        id: item.id,
        name: item.name,
        dueDate,
        dueState,
      };
    })
    .filter(isNonNullable);
  // The array of non null TimelineItemData
  return result;
};

type TimeDate = {
  date: Date;
};

/** Moves an array of time series to today's date */
export function fixMocksToday<T extends TimeDate>(data: T[], delta: number) {
  return data.map((d) => ({ ...d, date: new Date(d.date.getTime() + delta) }));
}

export function getRange<T extends TimeDate>(data: T[]): [Date, Date] {
  const set = data.map((d) => d.date);
  const dateRange = extent(set);
  return dateRange as [Date, Date];
}

type StartEnd = {
  startDate: string;
  endDate?: string | null;
};

/** Moves an array of time series to today's date */
export function fixMocksStartEnd<T extends StartEnd>(data: T[], delta: number) {
  return data.map((d) => {
    const obj = {
      ...d,
      startDate: new Date(new Date(d.startDate).getTime() + delta).toISOString(),
    };
    if (!d.endDate) return obj;
    return {
      ...obj,
      endDate: new Date(new Date(d.endDate).getTime() + delta).toISOString(),
    };
  });
}

export function getIsActiveProject(status: string) {
  switch (status) {
    case ProjectStatuses.ACTIVE:
    case ProjectStatuses.IN_PURSUIT:
    case ProjectStatuses.TEST_PROJECT:
      return true;
    default:
      return false;
  }
}

export function isGmpInsightsMilestone(m: InsightsMilestone) {
  return m.designPhase === DesignPhaseEnum['Guaranteed Maximum Price'];
}

export const calculateDrawPercentage = (remaining: number, total: number): string => {
  if (
    Number.isNaN(remaining) ||
    Number.isNaN(total) ||
    Number(remaining) === 0 ||
    Number(total) === 0
  ) {
    return EMPTY_COST;
  }
  const percentage = Math.round((remaining / total) * 100);
  return total ? renderPercentString({ value: percentage }) : '0%';
};

export const generateDesignPhaseChartInput = (
  designPhaseBreakdown: InsightsProjectsBreakdownItem[],
  selectedDisplayByOption: HeaderDisplayBy
): StackDataItem[] => {
  const reorderedItems = reorderInsightsProjects(designPhaseBreakdown);
  const sortByCountsSelection = selectedDisplayByOption === HeaderDisplayBy.COUNT;
  const dataItems: StackDataItem[] = [];

  reorderedItems.forEach((bi) => {
    const projectsWithAlertsValue = Number(
      sortByCountsSelection ? bi.countWithAlerts : bi.volumeWithAlerts
    );
    const projectsCountValue = Number(sortByCountsSelection ? bi.count : bi.volume);
    // Only push to dataItems if the value is non-zero
    if (projectsWithAlertsValue !== 0 || projectsCountValue !== 0) {
      dataItems.push({
        category: bi.label,
        values: [
          {
            name: 'projectsWithAlerts',
            value: projectsWithAlertsValue,
          },
          {
            name: 'projectsCount',
            value: projectsCountValue,
          },
        ],
      });
    }
  });

  return dataItems;
};

function reorderInsightsProjects(
  items: InsightsProjectsBreakdownItem[]
): InsightsProjectsBreakdownItem[] {
  return [...items].sort((a, b) => {
    const orderA = designPhaseOrderMap.indexOf(a.label);
    const orderB = designPhaseOrderMap.indexOf(b.label);
    return (orderA === -1 ? Infinity : orderA) - (orderB === -1 ? Infinity : orderB);
  });
}

const designPhaseOrderMap: string[] = [
  DesignPhaseEnum.Programming,
  DesignPhaseEnum['Pre-Design'],
  DesignPhaseEnum['Conceptual Design'],
  DesignPhaseEnum['Schematic Design'],
  DesignPhaseEnum['Design Development'],
  DesignPhaseEnum['Construction Documents'],
  DesignPhaseEnum['Guaranteed Maximum Price'],
  DesignPhaseEnum['As-Built Costs/Final Construction Costs'],
];

const keySortConfig: Record<InsightsSortKey, SortDirection> = {
  // Sort Descending
  [InsightsSortKey.COST]: SortDirection.SORT_DESCENDING,
  [InsightsSortKey.SIZE]: SortDirection.SORT_DESCENDING,
  [InsightsSortKey.LAST_UPDATED]: SortDirection.SORT_DESCENDING,
  [InsightsSortKey.ALERTS]: SortDirection.SORT_DESCENDING,
  // Sorty Ascending
  [InsightsSortKey.NAME]: SortDirection.SORT_ASCENDING,
  [InsightsSortKey.DELIVERY_TYPE]: SortDirection.SORT_ASCENDING,
  [InsightsSortKey.STATUS]: SortDirection.SORT_ASCENDING,
  [InsightsSortKey.DESIGN_PHASE]: SortDirection.SORT_ASCENDING,
  [InsightsSortKey.PROJECT_LEAD]: SortDirection.SORT_ASCENDING,
};

export const getSortDirection = (sortBy: InsightsSortKey): SortDirection => {
  return keySortConfig[sortBy];
};

export type DescriptionCount = {
  description: string;
  count: number;
  alertType: string;
};

type DescriptionCounts = Record<string, DescriptionCount>;

export const AlertDisplayNames: Record<Alerts, string> = {
  [Alerts.CONTINGENCY_ALLOW]: 'Contingencies & Allowances',
  [Alerts.GAP]: 'Gap',
  [Alerts.ITEMS]: 'Items',
  [Alerts.PATH_TO_BUDGET]: 'Path to budget',
};

export const getUniqueAlertDescriptionCounts = (alerts: InsightsActiveAlert[]) => {
  const descriptionCounts: DescriptionCounts = {};

  alerts.forEach((alert) => {
    if (!descriptionCounts[alert.description]) {
      descriptionCounts[alert.description] = {
        description: alert.description,
        count: 0,
        alertType: alert.alertType || '',
      };
    }
    descriptionCounts[alert.description].count += 1;
  });

  const uniqueAlerts = Object.values(descriptionCounts);

  return uniqueAlerts;
};

export const getAlertSectionID = (alert: DescriptionCount): string => {
  switch (alert.alertType) {
    case Alerts.GAP:
      return `#${InsightsTabId.Costs}`;
    case Alerts.ITEMS:
      return `#${InsightsTabId.Items}`;
    case Alerts.PATH_TO_BUDGET:
      return `#${InsightsTabId.Costs}`;
    case Alerts.CONTINGENCY_ALLOW:
      return `#${InsightsTabId.Contingencies}`;
    default:
      return '';
  }
};
