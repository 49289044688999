import { useMemo } from 'react';

import { colorWheel } from '../../theme/colors';
import { formatCost } from '../../utilities/currency';
import { pluralizeString } from '../../utilities/string';
import ProgramCostTrendlineChart from '../dragon-scales/TimelineCharts/ProgramCostTrendline/ProgramCostTrendline';
import PrintPageBreak from '../Print/PrintSharedComponents/PrintPageBreak';
import {
  costBreakdownTypeToColorMap,
  getProgramCostBreakdownHeaders,
  projectBreakdownChartOrder,
  projectCostBreakdownHeaders,
} from '../ProjectCostBreakdownReport/utils';

import AllProjectsFilteredOutEmptyState from './AllProjectsFilteredOutEmptyState';
import ProgramCombinedEstimateChart from './ProgramCombinedEstimateChart';
import ProgramDashboardHeader from './ProgramDashboardHeader';
import ProgramDashboardPieChart from './ProgramDashboardPieChart';
import ProgramDashboardProjectSelection from './ProgramDashboardProjectSelection';
import { ProgramDashboardData } from './ProgramDialog/hooks/useProgramDashboardHooks';

const colorWheelSlice5 = colorWheel.slice(5);

type Props = {
  data: ProgramDashboardData;
  isOpen: boolean;
  isPrinting: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export default function ProgramDashboard({ data, isOpen, isPrinting, setIsOpen }: Props) {
  const projectBreakdownOrderMap = useMemo(() => {
    const projectOrderMap = new Map<string, number>();
    const projectBreakdowns = data.info.breakdowns?.projects ?? [];
    const sortedBreakdown = [...projectBreakdowns].sort((a, b) => {
      if (a.cost === b.cost) return a.name < b.name ? 1 : -1;
      return Number(a.cost) < Number(b.cost) ? 1 : -1;
    });
    sortedBreakdown.forEach((b, i) => projectOrderMap.set(b.name, i));
    return projectOrderMap;
  }, [data.info.breakdowns?.projects]);

  const projectColors = new Map<string, string>();
  data.info.program?.projects.forEach((project, i) => {
    projectColors.set(project.name, colorWheelSlice5[i]);
  });

  const totalProgramCost = data.info.breakdowns?.total ?? 0;
  return (
    <div
      className={`flex w-full flex-col justify-center gap-6 overflow-visible p-6 ${
        !data.state.selectedProjectIDs.length ? 'h-full' : ''
      }`}
    >
      <ProgramDashboardHeader
        isOpen={isOpen}
        isPrinting={isPrinting}
        program={data.info.program}
        programCosts={data.info.programCosts}
        selectedProjectIDs={data.state.selectedProjectIDs}
        setIsOpen={setIsOpen}
      />
      <ProgramDashboardProjectSelection
        isOpen={isOpen}
        isPrinting={isPrinting}
        projects={data.info.program?.projects ?? []}
        selectedProjectIDs={data.state.selectedProjectIDs}
        setSelectedProjectIDs={data.state.setSelectedProjectIDs}
      />
      {!data.state.selectedProjectIDs.length && (
        <AllProjectsFilteredOutEmptyState
          program={data.info.program}
          setSelectedProjectIDs={data.state.setSelectedProjectIDs}
        />
      )}
      {!!data.state.selectedProjectIDs.length && data.info.program && (
        <>
          <ProgramCostTrendlineChart
            combined={data.info.trendline?.combined?.combinedCostTimeSeries ?? []}
            dataRange={[
              data.info.trendline?.combined?.minCost ?? 0,
              data.info.trendline?.combined?.maxCost ?? 0,
            ]}
            height={500}
            isPrint={false}
            separated={data.info.trendline?.separated}
            separatedProjectColors={projectColors}
            timeRange={[
              new Date(data.info.trendline?.minDate ?? 0),
              new Date(data.info.trendline?.maxDate ?? 0),
            ]}
          />
          <PrintPageBreak />
          <ProgramDashboardPieChart
            breakdown={data.info.breakdowns?.projects ?? []}
            colorMap={projectColors}
            headers={projectCostBreakdownHeaders}
            label={data.state.selectedProjectIDs.length.toString()}
            name="Breakdown By Project"
            orderingMap={projectBreakdownOrderMap}
            shouldTableChartData
            sublabel={pluralizeString('Project', data.state.selectedProjectIDs.length)}
            total={totalProgramCost}
          />
          <ProgramDashboardPieChart
            breakdown={data.info.breakdowns?.program ?? []}
            colorMap={costBreakdownTypeToColorMap}
            headers={getProgramCostBreakdownHeaders(true)}
            label={formatCost(totalProgramCost, { rounded: true })}
            name="Breakdown By Cost"
            orderingMap={projectBreakdownChartOrder}
            sublabel="Running Total"
            total={totalProgramCost}
          />
          <PrintPageBreak />
          {!!data.info.programEstimateChartInfo?.categorizations.length && (
            <ProgramCombinedEstimateChart
              programCategorizedTotals={data.info.programCategorizedTotals ?? []}
              programEstimateChartInfo={data.info.programEstimateChartInfo}
              projects={data.info.program.projects}
              selectedGroupingID={data.state.selectedGroupingID}
              selectedUnitAbbreviation={data.state.selectedUnitAbbreviation}
              // isLoading: boolean;
              setSelectedGroupingID={data.state.setSelectedGroupingID}
              setSelectedUnitAbbreviation={data.state.setSelectedUnitAbbreviation}
            />
          )}
        </>
      )}
    </div>
  );
}
