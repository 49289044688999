import { useCallback, useMemo } from 'react';
import { Link, To } from 'react-router-dom';

import { BusinessOutlined, ErrorOutline, LocationOnOutlined } from '@material-ui/icons';

import { InsightsEvent, insightsEvent } from '../../../analytics/analyticsEventProperties';
import { InsightsActiveAlert, InsightsSort, InsightsSortKey } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { fromNow } from '../../../utilities/dates';
import { Thumbnail } from '../../dragon-scales';
import { SizeIcon } from '../../Nav/icons';
import { Chip, Icon, Tooltip } from '../../scales';
import ProjectTableAlertTooltip from '../ToolTips/ProjectTableAlertTooltip';
import ProjectTableChipsTooltip from '../ToolTips/ProjectTableChipsTooltip';

type Props = {
  alerts: InsightsActiveAlert[];
  onClick: () => void;
  project: InsightsProjects;
  sortState: InsightsSort;
  to: To;
};

export default function ProjectTableEntry(props: Props) {
  const sendAnalytics = useSendAnalytics();

  const { allChips, displayChip, lastUpdated } = useMemo(
    () => getChipsBySort(props.project, props.sortState.sortKey),
    [props.project, props.sortState.sortKey]
  );

  const handleTooltipOpen = useCallback(() => {
    sendAnalytics(
      insightsEvent(InsightsEvent.SUMMARY_PROJECT_TOOLTIP_VIEW, {
        projectID: props.project.id,
        projectName: props.project.name,
      })
    );
  }, [props.project.id, props.project.name, sendAnalytics]);

  return (
    <div className="flex items-center gap-2">
      <Thumbnail size={60} thumbnail={props.project.thumbnail} />

      <div className="flex flex-col flex-wrap justify-center gap-2">
        {/* Project Name */}
        <Tooltip
          content={<div className="inline w-48 type-table-text">{props.project.name}</div>}
          onOpen={handleTooltipOpen}
        >
          <Link onClick={props.onClick} to={props.to}>
            <div className="line-clamp-1 text-type-link type-heading3">{props.project.name}</div>
          </Link>
        </Tooltip>

        {/* Project Details */}
        <div className="flex items-center gap-1">
          {/* Project Alerts */}
          {props.project.activeAlerts.length > 0 && (
            <Tooltip
              content={<ProjectTableAlertTooltip triggeredAlertsByProject={props.alerts} />}
              onOpen={handleTooltipOpen}
            >
              <Chip
                icon={<ErrorOutline color="error" />}
                text={<div className="text-type-error">{props.project.activeAlerts.length}</div>}
              />
            </Tooltip>
          )}
          {/* Project Detail Chips */}
          <Tooltip
            content={<ProjectTableChipsTooltip allChips={allChips} lastUpdated={lastUpdated} />}
            onOpen={handleTooltipOpen}
          >
            <div className="flex items-center gap-2">
              <div className="flex items-center">{displayChip}</div>
              {allChips.length ? (
                <div className="flex type-table-text">+{allChips.length}</div>
              ) : null}
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

type ChipDetails = {
  allChips: JSX.Element[];
  lastUpdated?: JSX.Element;
  displayChip?: JSX.Element;
};

const getChipsBySort = (project: InsightsProjects, sortKey: InsightsSortKey): ChipDetails => {
  const chips = generateChips(project);
  const displayChip = selectDisplayChip(chips, sortKey);
  let lastUpdated: JSX.Element | undefined;
  const filteredChips: JSX.Element[] = [];

  chips.forEach((chip) => {
    if (chip.key === 'lastUpdated') {
      // Get the last udpated time chip
      lastUpdated = chip;
    } else if (chip.key !== displayChip?.key) {
      // If the chip is displayed then dont add in teh allChips tooltip
      filteredChips.push(chip);
    }
  });

  return {
    allChips: filteredChips,
    lastUpdated,
    displayChip,
  };
};

const generateChips = (project: InsightsProjects): JSX.Element[] => {
  const chips: JSX.Element[] = [];

  // Add project chips conditionally
  const allChips = [
    project.location && (
      <Chip key="location" icon={<LocationOnOutlined />} text={project.location} />
    ),
    project.type && project.type !== 'Unassigned' && (
      <Chip key="type" icon={<BusinessOutlined />} text={project.type} />
    ),
    project.deliveryType && <Chip key="deliveryType" text={project.deliveryType} />,
    project.projectLeadName && (
      <Chip
        key="lead"
        icon={<img alt="project lead icon" src="/img/GroupsOutlined.svg" width={16} />}
        text={project.projectLeadName}
      />
    ),
    ...(project.orgNodes?.map((node) =>
      node ? (
        <Chip
          key={`orgNode-${node.id}`}
          icon={<Icon name="group_by" size="sm" />}
          text={node.name}
        />
      ) : null
    ) || []),
    project.status && <Chip key="status" text={project.status} />,
    project.milestoneDesignPhase && <Chip key="designPhase" text={project.milestoneDesignPhase} />,
    project.milestoneGSF && Number(project.milestoneGSF) !== 0 && (
      <Chip
        key="size"
        icon={<SizeIcon />}
        text={`${Number(project.milestoneGSF).toLocaleString()} GSF`}
      />
    ),
    project.lastUpdated && (
      <div key="lastUpdated" className="line-clamp-1 type-table-text">
        Last updated {fromNow(project.lastUpdated)} by {project.lastUpdatedBy}
      </div>
    ),
  ];

  // Add valid chips
  allChips.forEach((chip) => {
    if (chip) {
      chips.push(chip);
    }
  });

  return chips;
};

const selectDisplayChip = (
  chips: JSX.Element[],
  sortKey: InsightsSortKey
): JSX.Element | undefined => {
  const chipMap: Record<InsightsSortKey, string | undefined> = {
    [InsightsSortKey.ALERTS]: 'alerts',
    [InsightsSortKey.COST]: 'location',
    [InsightsSortKey.DELIVERY_TYPE]: 'deliveryType',
    [InsightsSortKey.DESIGN_PHASE]: 'designPhase',
    [InsightsSortKey.SIZE]: 'size',
    [InsightsSortKey.STATUS]: 'status',
    [InsightsSortKey.NAME]: 'location',
    [InsightsSortKey.PROJECT_LEAD]: 'lead',
    [InsightsSortKey.LAST_UPDATED]: 'lastUpdated',
  };

  const key = chipMap[sortKey];
  return key ? chips.find((chip) => chip.key === key) : undefined;
};
