import { FC } from 'react';
import { useParams } from 'react-router-dom';

import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close, Help } from '@material-ui/icons';

import { UserReportType } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { Button, Checkbox, Tooltip } from '../../scales';
import DialogsStyles from '../DialogsStyles';

import DialogsReportsContent from './DialogsReportsContent/DialogsReportsContent';

type DialogReportsProps = {
  allowableReportTypes: UserReportType[];
  classes: Classes<typeof DialogsStyles>;
  inputReport: UserReportInput;
  isFollowingActiveMilestone?: boolean;
  onClose: () => void;
  onSubmit: () => void;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  open: boolean;
  setInputReport: (newInput: UserReportInput) => void;
  variant: 'reports-tab' | 'reports-manager';
  hasDistribution?: boolean;
  hasReportDistributionPermission?: boolean;
  shouldCreateReportDisribution: boolean;
  setShouldCreateReportDisribution: (value: boolean) => void;
};

const DialogReports: FC<DialogReportsProps> = ({
  allowableReportTypes,
  classes,
  hasDistribution,
  inputReport,
  isFollowingActiveMilestone,
  onClose,
  onSubmit,
  open,
  setInputReport,
  variant,
  hasReportDistributionPermission,
  shouldCreateReportDisribution,
  setShouldCreateReportDisribution,
}) => {
  const showReportDistributionExperiment =
    !inputReport.id &&
    inputReport.reportType === UserReportType.USER_REPORT_MSR && // starting with MSR only
    hasReportDistributionPermission;

  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID not found');

  const header = inputReport.id ? 'Edit Report' : 'New Report';
  const onCloseDialog = () => {
    onClose();
    setShouldCreateReportDisribution(false);
  };

  return (
    <Dialog
      classes={{
        paper: classes.dialogPaperSmall,
      }}
      maxWidth={false}
      onClose={onCloseDialog}
      open={open}
    >
      <div className={classes.titleContainer}>
        <div>
          <Typography variant="title">{header}</Typography>
        </div>
        <IconButton className={classes.close} title="Close dialog">
          <Close onClick={onCloseDialog} />
        </IconButton>
      </div>
      <Divider />
      <DialogContent className={classes.content}>
        <DialogsReportsContent
          allowableReportTypes={allowableReportTypes}
          hasDistribution={hasDistribution ?? false}
          isFollowingActiveMilestone={isFollowingActiveMilestone}
          onSubmit={onSubmit}
          projectId={projectId}
          setUserReport={setInputReport}
          userReport={inputReport}
          variant={variant}
        />
        {showReportDistributionExperiment && (
          <div className="pt-4">
            <Checkbox
              isDisabled={!inputReport.shared}
              isSelected={shouldCreateReportDisribution}
              onChange={() => setShouldCreateReportDisribution(!shouldCreateReportDisribution)}
            >
              <div className="flex items-center gap-1">
                Create a Scheduled Distribution for this Report
                <Tooltip content="This Report must be Shared with Team to create a Distribution">
                  <div>
                    <Help style={{ width: 16, height: 16 }} />
                  </div>
                </Tooltip>
              </div>
            </Checkbox>
          </div>
        )}
      </DialogContent>
      <DialogActions className={classes.action}>
        <Button label="Cancel" onClick={onCloseDialog} type="secondary" />
        <Button
          data-cy="confirm-dialog--ok-button"
          isDisabled={inputReport.name === '' || inputReport.reportType === UserReportType.NONE}
          label={inputReport.id ? 'Update' : 'Save'}
          onClick={onSubmit}
          type="primary"
        />
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(DialogsStyles)(DialogReports);
