import ProjectSelectionList from '../ProjectsList/ProjectSelection/ProjectSelectionList';
import ProjectSelectionListEntry from '../ProjectsList/ProjectSelection/ProjectSelectionListEntry';

type Props = {
  isOpen: boolean;
  isPrinting: boolean;
  projects: ForecastingProject[];
  selectedProjectIDs: UUID[];
  setSelectedProjectIDs: (newIDs: UUID[]) => void;
};

export default function ProgramDashboardProjectSelection({
  isOpen,
  isPrinting,
  projects,
  selectedProjectIDs,
  setSelectedProjectIDs,
}: Props) {
  if (!isPrinting && isOpen)
    return (
      <div>
        {/* The enclosing div above is necessary to avoid having a scrollbar */}
        <ProjectSelectionList
          key="ProjectSelectionList"
          fetchMore={() => {}}
          hasHover={false}
          isLink
          onChange={setSelectedProjectIDs}
          projects={projects}
          searchTerm=""
          selectedProjectIDs={selectedProjectIDs}
          selectionMode="multiple"
        />
      </div>
    );

  if (isPrinting)
    return (
      <>
        {projects.map((project) => (
          <div key={project.id} className="px-4">
            <ProjectSelectionListEntry key={project.id} project={project} searchTerm="" />
          </div>
        ))}
      </>
    );

  return null;
}
